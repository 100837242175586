@mixin calc($property, $expression) {
	#{$property}: -moz-calc(#{$expression});
	#{$property}: -webkit-calc(#{$expression});
	#{$property}: calc(#{$expression});
}

.detail-box {
	box-sizing: border-box;
    height: 150px;
    border: 2px dotted #CCD2DE;
    border-radius: 5px;
    background-color: #FFFFFF;
    overflow-y: hidden;
}

.sidebar-items{
    padding-left:10px;
    padding-right:10px;
}

.sidebar-items-text{
	color: #666666;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 11px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 13.5px;
    padding-top:14px;
    padding-bottom: 5px;
}

.sidebar-star{
	height: 13px;
	width: 4px;
	color: #F87622;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 11px;
	font-weight: bold;
	line-height: 13.5px;

}

.sidebar-textbox {
	height: 40px;
    width: 100%;
    border: 1px solid #CCD2DE;
    border-radius: 4px;
    background-color: #FFFFFF;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    padding-left: 10px;
    font-size: 16px;
    line-height: 16px;
    color: #4A4A4A;
}

.sidebar-textarea{
    @extend .sidebar-textbox;
    max-width: 100%;
    height: 120px;
    padding-top: 6px;
    padding-right: 2px;
}

button:active, .button:active, button:focus, .button:focus, button:hover, .button:hover{
    outline:none !important;
}

textarea:active,textarea:focus{
    outline:none !important;
}

.standard-item{
    padding:7px 0 7px 0; 
    display: flex;
    flex-direction: row;
}

.standard-item-text{
	color: #464B56;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 15px;
	line-height: 15px;
    display: flex;
    width: 70%;
}

.standard-item-img{
    color: #E2E2E2;
    font-size: 14px;
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;   
}

.standard-item-count{
    color: #FFFFFF;
    font-size: 9px;
    font-weight: bold;
    line-height: 17px;
    border: 1px solid #4A4A4A;
    height: 15px;
    width: 15px;
    margin-left: 76px;
    float: right;
    border-radius: 3px;
    background-color: #4A4A4A;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

input.red-border{
    border: 1px solid #d01e1f;
}

textarea.red-border{
    border: 1px solid #d01e1f;
}

.success-message{
    position: relative;
    width: 300px;
    height: 120px;
    border-radius: 15px;
    background-color: rgba(255,255,255,0.9);
    opacity: 0.5;
    display: flex;
    text-align: center;
    margin: auto;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.5);
    animation: fadeinout 4s linear forwards;
}

.message-name{
    display: flex;
    text-align: center;
    align-content: center;
    align-self: center;
    justify-content: center;
    color: #464B56;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    line-height: 15px;
    margin: auto;
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  10% { opacity: 1; }
}

.text-info-newunit{
    align-self: center;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    color: #9B9B9B;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    font-size: 15px;
}

.search-component{
    //width:360px;
    height: calc(100% - 60px);
    // position: relative;
    // float:right;
    background-color: #F5F5F5;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    //border-left: 1px solid #CCD2DE;
}

.search-content{
   
    // margin-top: 20px 
}

.textbox-icon-search{
    color: #9B9B9B;
    position: absolute;
    padding-top: 7px;
    padding-left: 5px; 
}

.checkbox-option-text{
    margin-left: 4px;
    font-weight: normal;
    //font-family: "proximaNova";
    font-family: "Open Sans", sans-serif;
}

.dropdown-select{
    width:100%;
    overflow: auto;
    border-left:1px solid #CCCCCC;
    border-right:1px solid #CCCCCC;
    border-bottom:1px solid #CCCCCC;
    border-radius: 4px;
    background-color: #FFFFFF;
    z-index: 100;
}

.checkbox-option{
    display: block;
    margin-left:6px;
}

.dropdown-search{
    font-size: 5px;
    padding-top: 10px;
    padding-left: 23px;
    color: #9B9B9B;
    position: absolute;
    padding-right: 10px;
    margin-top: 5px;
}

.global-textbox-icon-search {
    color: #9B9B9B;
    position: absolute;
    padding-left: 6px;
    padding-right: 4px;
    margin-top: 5px;
    font-size: 18px;
}

.lesson-icon-search {
    color: #9B9B9B;
    position: absolute;
    padding-left: 6px;
    margin-top: 5px;
    font-size: 18px;
}

.searchbar-seperator{
    box-sizing: border-box;
	border-top: 1px solid #CCD2DE;
}

.lesson-search{
    //padding:0 20px 20px 20px;
    height : calc(100% - 120px);  
    overflow: auto;
}

.searchbar-textbox{
    padding: 20px;
    border-bottom: 1px solid #CCD2DE;
    background-color: #F5F5F5;
    //width:300px;
}

.search-result-count{
	color: #666666;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 13px;
    padding: 15px 20px 15px 20px;
}

.lesson-search-image{ 
	height: 40px;
	width: 57px;
	border: 1px solid #F5F5F5;
	border-radius: 3px;
	background-color: #FFFFFF;
	box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1), 0 4px 12px 0 rgba(0,0,0,0.05);
    overflow: hidden
}

.lesson-name-search{
    float: left;
    width:170px;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 12px;
	line-height: 14px;
    max-height: 42px;
    align-self: center;
    // overflow: hidden;
	// text-overflow: ellipsis;
	// white-space: nowrap;
	color: #4A4A4A;
}

.lesson-description-search{
	width: 80px;
	color: #9B9B9B;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 8px;
	line-height: 10px;
     overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.lesson-searchbar-seperator{
    border-bottom: 1px solid rgb(204, 210, 222);
    padding-top: 5px;
    margin-left: 57px;
}

.lesson-search-add-icon{
    cursor: pointer;
    color: #46C56D;
    font-size: 27px;
    align-self: center;
}

.lesson-search-view-icon{
    @extend .lesson-search-add-icon;
    color:#009BBD;
}

.lesson-search-fadded-icon{
    @extend .lesson-search-add-icon;
    color:#ccc;
}

.lesson-search-delete-icon{
    @extend .lesson-search-add-icon;
    color:#d01e1f;
}

.lesson-search-remove-icon{
    cursor: pointer;
    color: #FFFFFF;
    border: 1px solid #d01e1f;
    margin-top: 6px;
    margin-left: 6px;
    font-size: 14px;
    padding: 2px;
    border-radius: 50%;
    margin-bottom: 10px;
    background: #d01e1f;
}

.some-area-new-unit {
  > div {
    padding: 1rem;
    &:nth-child(1) {
      background-color: white;
      flex-basis:auto;
      flex-grow: 1;
      flex-shrink:1;
       width:85%;
       overflow-x: hidden
    }
    &:nth-child(2) {
      width: 385px;
      background-color: #F8F8F8;
      border-left:1px solid #CCD2DE;
      margin-right: 0;
      transition: margin 0.3s ease;
    }

  }
}

.new-unit-some-area-without-info{
  > div {
    padding: 1rem;
    &:nth-child(1) {
      background-color: white;
      flex-basis: auto;
      flex-grow:1;
      flex-shrink:1;
      width:100%;
      overflow-x: hidden
    }
    &:nth-child(2) {
      border-left:1px solid #CCD2DE;
      width:385px;
      margin-right: -385px;
      transition: margin 0.3s ease;
    }

  }
}

.new-unit-some-area-initial {
  > div {
    padding: 1rem;
    &:nth-child(1) {
      background-color: white;
      width:85%;
      overflow-x: hidden
    }
    &:nth-child(2) {
      width:385px;
      background-color: #F8F8F8;
      border-left:1px solid #CCD2DE;
      margin-right: 0;
    }

  }
}

.panel-lesson{
    height: 75px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    line-height: 18px;
    font-size: 15px;
    color: #9B9B9B;
}

.unit-remove-popover{
  background-color: white;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  display: flex;
  position: absolute;
  right: 29%;
  z-index: 2;
  border: 1px solid #F5F5F5;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
  padding: 20px 20px 20px 20px;
  margin-right: 20px;
}

.popover-remove-text{
    line-height: 30px;
    font-weight: normal;
    color:#d01e1f;
}

 .no-select {
 -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
       -ms-user-select: none; /* Internet Explorer/Edge */
           user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome and Opera */
}

.remove-lesson{
    color:#999999;
    font-size: 20px;
}

.items-background{
    background: #FFFFFF;
    padding: 10px 15px 10px 15px;
    border-radius:6px; 
}