.e-reader-audio-player-container {
  position: absolute;
  top: 60px;
  right: 100px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
  padding-top: .5em;
  padding-bottom: .25em;
  padding-left: 3em;
  padding-right: 3em;
  background-color: white;
}

#flowPaperRender {
  background-color: #585858;
}

.e-reader-mobile-menu-item {
  display: block;
  cursor: pointer;
  i.e-reader-header-icons {
  }
  .e-reader-mobile-menu-text {
    font-style: normal;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    color: #666666;
    vertical-align: text-bottom;
    line-height: 2em;
  }
}

.write-a-review{
  color: #CC0B1F;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  margin-top: 25px;
}

.e-reader-header, .mobile-e-reader-header {
  background-color: #FFFFFF;
  height: 60px;
  box-shadow: 0 2px 6px 0 rgba(68,68,68,0.25);
}

.e-reader-header-2 {
  width: 100%;
  position: fixed;
  z-index: 10;
}

.e-reader-header{
  width: 100%;
  position: fixed;
  z-index: 10;

  .non-mobile-header {
    // only pad for the non mobile view
    .e-reader-header-back-icon {
      margin-top: 20px;
    }
  }

  .book-title {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 28px); // 28 is padding for the icon to the right in the header
    float: right;
    overflow: hidden;
  }
  .header-vertical-line {
    @media (max-width: 575px) {
      display: none;
    }
  }
}

.navbar-brand.e-reader {
  @media (max-width: 575px) {
    max-width: calc(100% - 235px);
  }
  max-width: calc(100% - 300px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-reader-header-back-icon{
  margin-right: 10px;
}

.navbar-header {
  width: 100%;
  .icon-bar {
    background-color: #009BBD !important;
  }
}

.navbar-toggle {
  display: block !important;
}

#e-reader-hamburger {
  margin-right: 0px !important;
  margin-left: 5px;
  border-color: #009BBD;
}
#e-reader-hamburger:focus, #e-reader-hamburger:hover {
  background-color: unset;
}

#e-reader-hamburger.active {
  background-color: #009BBD !important;
  .icon-bar {
    background-color: white !important;
  }
}

.e-reader-header-icons{
  font-size: 30px;
  margin-right: 10px;
  margin-top: 5px;
  color: #9B9B9B;
  cursor: pointer;
  @media (max-width: 575px) {
    margin-right: 0px;
  }
}

.e-reader-footer-text {
  height: 11px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 8px;
  font-weight: 600;
  line-height: 2px;
  text-align: center;
}

.e-reader-footer-item{
  margin-top: auto;
  margin-bottom: auto;
  padding: 10px;
  width: 70px;
  justify-content: center;
  text-align: center;
}

.e-reader-holder{
  position: absolute;
  top: 60px;
  bottom: 60px;
  left: 0;
  right: 0;
  overflow: auto;
}

.e-reader-book-back{
  position: absolute;
  height: 46px;
  width: 46px;
  left:0px;
  top: calc(50% - 23px);
  border-radius: 0 9px 9px 0;
  box-shadow: 0 2px 6px 0 rgba(68,68,68,0.25);
  padding-top: 8px;
  padding-left: 7px;
}

.e-reader-book-back:hover{
  cursor: pointer;
}

.e-reader-book-next{
  position: absolute;
  height: 46px;
  width: 46px;
  right:0px;
  top: calc(50% - 23px);
  border-radius: 9px 0px 0px 9px;
  box-shadow: 0 2px 6px 0 rgba(68,68,68,0.25);
  padding-top: 8px;
  padding-left: 10px;
}

.e-reader-book-next:hover{
  cursor: pointer;
}

.e-reader-turn {
  opacity: 0.5;
  font-size: 20px;
}

.e-reader-book-holder{
  position: absolute;
  left: 70px;
  right: 70px;
  //top: 60px;
  top: 10px;
  bottom: 90px;
  background-color: #e9e9e9;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
}

.e-reader-book-holder-2{
  position: absolute;
  left: 6px;
  right: 6px;
  top: 0px;
  bottom: 0px;
  background-color: #f0f0f0;
}

.e-reader-book-holder-3{
  position: absolute;
  left: 6px;
  right: 6px;
  top: 0px;
  bottom: 0px;
  background: #FFFFFF;
}

.icon-red {
  color: #CC0000 !important;
  cursor: pointer;
}