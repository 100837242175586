.editable-annotation:hover {
  cursor: pointer;
}

.delete-highlight {
  color: #009bbd;
}

.highlight-yellow {
  background-color: #FFE61B;
}

.note-style {
  color: #009bbd;
  //font-style: italic;
}

.icon-note{
  color: #009bbd;
  background-color: #FFFFFF;
}

.annotation-note-container {
  display: inline-block;
  position: relative;
}

.annotation-note-hidden{
  visibility: collapse;
}

.annotation-note{
  position: absolute;
  top: calc(50% - 80px);
  left: calc(50% - 140px);
  width:280px;
  height:160px;
  //background-color: #009CBD;
  background-color: #ffffff;
  vertical-align: top;
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.5);
  border: 2px solid #F5F5F5;
  padding: 15px;
}

.annotation-note-title{
  color: #999999;
  font-size: 10px;
}

.annotation-note-text{
  //margin: 2px;
  //margin-left: 4px;
  //margin-right: 4px;
  //width: calc(100% - 8px);
  //border-radius: 2px;
  //border-color: #999999;
  height: 76px;
  resize: none;
  width: 100%;
  border-color: transparent;
}

.annotation-note-divider {
  background-color: #999999;
  height: 1px;
  margin-bottom: 15px;
}

.annotation-note-save {
  color: #009BBD;
  font-size: 13px;
  float: right;
}

.annotation-note-save:hover {
  cursor: pointer;
}

.annotation-note-delete {
  float: right;
  color: #CC0B1F;
  font-size: 13px;
  padding-right: 15px;
}

.annotation-note-delete:hover {
  cursor: pointer;
}

.annotation-note-delete-hidden{
  visibility: collapse;
}

.annotation-note-cancel {
  color: #009BBD;
  font-size: 13px;;
  float:left
}

.annotation-note-cancel:hover {
  cursor: pointer;
}