$login-background-color: rgba(242,242,242,1);

// TODO: move to signup.scss
.signup {
  &_btn {
      width: 48% !important;
      text-align: center;
  }
  &_btn-row {
    display: flex;
    justify-content: space-between;
  }

}

.text {
  &-help {
    text-align: left;
    color: grey;
  }
}
// 


.bg-color{
  background-color: $login-background-color;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: #f2f2f2;
}

.login-box{
  padding-top: 200px;
  height: 100%;
  width: 370px;
  margin:auto;
}

.picture-login-box {
  width: 45vw;
  padding: 2em;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.set-picture-grid {
  position: absolute;
  width: 100% !important;
  height: calc(100% - 120px);
  overflow-y: scroll;

  .set-picture-row {
    max-width: 970px;
    margin-left: auto !important;
    margin-right: auto !important;

    .col-sm-3, .col-md-2 {
      padding-bottom: 1em !important;
    }
  }
}


.format-button {
  background-color: #009BBD;;
  color: white;
  padding: 14px 10px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 80%;
  border-radius: 6px;
  font-size: 15px;
  line-height: 10px;
}

button:hover {
  opacity: 0.8;
}

.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

img.avatar {
  height: 125px;
  width: 160px;
}

.container {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

.align-textbox {
  text-align: center;
  padding: 0;
}

.align-button {
  text-align: center;
  padding: 15px;
}

.align-link {
  text-align: center;
  text-decoration: underline;
  color: #666666;
}

.select-picture-passwordtext {
  text-align: center;
  color: #666666;
  font-size: 18px;
  margin-top: 20px;
}

.my-icon {
  height:20px;
  width: 20px;
}

// input[type=text]{
//   background: white url('../icons/SVG/icon-user.svg') no-repeat;
//   color: #444444;
//   background-size: 20px 20px;
//   background-position: 10px 10px;
//   border: 2px solid #F2F2F2;
//   border-radius: 6px 6px 6px 6px;
//   width: 100%;
//   padding: 12px 40px;
//   display: inline-block;
//   font-family: "ProximaNova";

// }

input:-webkit-autofill {
  background-color: white !important;
}

// Base for username, password, and domain inputs
.login-input {
  color: #444444;
  width: 100%;
  border-radius: 6px 6px 6px 6px;
  width: 100%;
  padding: 12px 40px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  border: 2px solid #F2F2F2;
  display: inline-block;
}

.user-password {
  @extend .login-input;
  border-width: 1px;
}

.error-format{
  color:  #d01e1f;
  text-align:left;
  padding-bottom:10px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
}

// User id when domain is not provided
.userid{
  color: #444444;
  background-size: 20px 20px;
  @extend .login-input;
}

// User id input when domain is provided
.userid-domain {
  @extend .userid;
  width: 50%;
}

// Domain label
.domain {
  @extend .userid-domain;
  background: none;
  border-color: #e1e1e1;
  color: #909090;
  background-color: #eeeeee;
  padding-left: 5px;
  padding-right: 5px;
}

#red-border{
  border: 1px solid #d01e1f;
}

input:invalid {
  box-shadow: none;
}

input:focus {
  outline: none;
}

#alert-danger {
  background-color: #f1dedf;
  border-color: #ebccd1;
  color: #d01e1f;
}

.login-icons{
    color: #9B9B9B;
    position: absolute;
    padding-top: 12px;
    font-size: 18px;
    padding-left: 9px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #9B9B9B;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #9B9B9B;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #9B9B9B;
}

:-moz-placeholder { /* Firefox 18- */
  color: #9B9B9B;
}

body{
  -ms-overflow-style: scrollbar !important;
}

img{
  -ms-interpolation-mode: bicubic;
}
.forgot-password-message{
  //font-family:"ProximaNova";
  font-family: "Open Sans", sans-serif;
  color: #444444;
  text-align:center;
  margin-bottom:10px;
  margin-top:20px;

}
.go-back-to-login{
  font-size: 10px;
  margin-right: -10px;
}
.reset-link-message{
  //font-family:"ProximaNova";
  font-family: "Open Sans", sans-serif;
  color: #444444;
  text-align: justify;
}
.mailer-imgcontainer{
  text-align: center;
  margin: 24px 0 12px -75px;
}
.mailer{
  height: 200px;
  width: 350px;
}
