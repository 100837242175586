//$widthValue:calc(100% - 300);
$widthValue:calc(100% - 300px);
@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.hero {
    height: 100px;
    width: 100%;
    background: linear-gradient(rgba(60,140,180,1), rgba(0,156,189,1));
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

.teaching-resources {
    height: 124px;
    color: #FFFFFF;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 52px;
    font-weight: 800;
    line-height: 55px;
    padding: 40px 100px 60px 48px;
}

.rectangle-2 {
    box-sizing: border-box;
    height: 30px;
    width: 125px;
    border: 1px solid #3498DB;
    border-radius: 8px 0 0 8px;
    background-color: #009BBD;
}

.rectangle-2-copy-2 {
    box-sizing: border-box;
    height: 30px;
    width: 140px;
    border: 1px solid #9B9B9B;
    border-radius: 0 8px 8px 0;
    background-color: #FFFFFF;
}

.toggle-resource {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align:center;
}

.language_active {
    color:  red;
}

.language {
    height: 13px;
    width: 60px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    font-weight: bold;
    line-height: 5px;
    text-align: center;
}

.featured-reading {
    color: #4A4A4A;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 21px;
    font-weight: 800;
    line-height: 26px;
    vertical-align:middle;
    padding-left: 18px;
    text-transform: uppercase;
}

.product {
    height: 60px;
    width: 60px;
    border: 3px solid #F5F5F5;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1), 0 4px 12px 0 rgba(0,0,0,0.05);
}

.arrow-center {
    margin-top:30px;
}

.navigate-scroll {
    height: 50px;
    max-width: 100%;
}

.slider-container {
    padding: 0 30px 0 30px;
    margin-bottom: 20px
}

.reading-item{
    padding: 15px;
    max-width: 100%;
}

.image-container{
    width:100%;
    height: autp;
}

.slider-name {
    max-width: 100%;
    color: #4A4A4A;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
    line-height: 20px;
    margin-top: 10px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor:pointer;
    padding-left: 3px;
    padding-right: 3px
}

.slider-sub-name {
    max-width: 100%;
    color: #9B9B9B;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor:pointer;
    padding-left: 3px;
    padding-right: 3px;
}

#nav-buttons .btn {
    padding:3px 12px 6px 12px;
}

.image-fixed-width {
    height: 200px;
    width:266px
}

.resources-item-container {
    width:266px;
    margin:20px;
    float: left;
}

.resource-product {
    background-color: #FFFFFF;
    //box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 0 rgba(0, 0, 0, 0.05);
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 6px 6px 0 0;
    object-fit: contain !important;
}

.folder-image{
  //background-color: #33AFCA;
  position: relative;
  background-size: 132px 20px;
  background-position: center bottom;
  width: 132px;
  height: 132px;
  cursor: pointer;
  border-radius: 0;
  object-fit: contain !important;
  z-index: 1;
  overflow-y: hidden;
}
.folder-image img{
  width: 132px;
  height: 132px;
  border-radius:0;
  z-index: 0;
  position: absolute;
  bottom: -9px;
  object-fit: contain !important;
}
.resource-heading {
    @extend .featured-reading;
    position: sticky;
    top: 0;
    background: #FFF;
    z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.resource-heading h4{
  font-size: 18px;
  font-weight: 400 !important;
  text-transform: none;
  max-width: 50%;
  margin-bottom: 0;
}

.resource-heading a{
  color: #d94e3b;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline!important;
  max-width: 50%;
  margin-right: 5px;
}

.resource-heading a:hover{
  color: #d94e3b;
  cursor: pointer;
  text-decoration: underline!important;
}

.resource-heading img{
  margin-right: 5px;
}

.resource-data {
    display:flex;
    margin-top:10px;
}

.add-resource-image {
    height: 15px;
    width: 15px;
    float: right;
    color: #9B9B9B
}

.resource-name {
    max-width:90%;
    width:87%
}

.add-resource {
    display:flex;
    max-width:13%;
    margin:auto;
    cursor:pointer;
}

.caurosel-arrowsize {
    z-index: 1;
    height: 25px;
    width: 25px;
}

.resource-number {
    font-weight: 200;
}

.image-center-resources {
    cursor: pointer;
    overflow-y: hidden;
    border: 3px solid #F5F5F5;
    border-radius: 8px;
    background-color: #F5F5F5;
}
@media screen and (max-width: 1376px) and (min-width: 992px){
    .browse-content-main {
        width:100% !important;
        margin:auto !important;
        padding-left:12px;
    }
}
@media screen and (max-width: 1116px) and (min-width: 992px){
    .recommended-image-size {
        width:230px !important;
        height: 170px !important;
    }
    .recommended-text-size{
        width:230px !important;
    }
    .bg-not-clickable{
        width:230px !important;
    }
    .browse-content-main-with-search{
        width: calc(100% + 354px) !important;
    }
}

@media screen and (max-width: 922) and (min-width: 768px){
    .resource-sm-with-search {
        width:100% !important;
        float: left;
    }
}


@media screen and (max-width: 1024px) {
    // .recommended-image-size{
    //     width:225px !important;
    //     height: 170px !important;
    // }

    // .recommended-text-size{
    //     width:225px !important;
    // }

    // .browse-content-main{
    //     width:992px !important;
    //     margin-left:25px !important;

    // }
    // .image-browse-item-book{
    //     height: 164px !important;
    // }
    // .browse-content-main-with-search{
    //     width:1034px !important;
    //     margin:auto;
    //     padding-left: 25px !important;
    // }
}
.row-seperator {
    border-bottom: 1px solid #979797;
    opacity: 0.5;
    margin-left:114px;
}
.text-component-row-padding {
    padding-top:8px !important;
    padding-bottom: 8px !important;
}
.text-component-row-heading {
    padding-top: 14px !important;
}
@media  (max-width: 768px) and (min-width: 576px){
    .sm-show{
        visibility: visible;
    }
}
@media screen and (max-width:576px) {
    .recommended-image-size {
        width:80px !important;
        height: 56px !important;
        float: left;
        border-radius: 6px;
        border: 1px solid #F5F5F5;
    }

    .recommended-text-size {
        width:100% !important;
    }
    // .browse-content-main {
    //     width:100% !important;
    //     padding-left:20px !important;
    //     padding-right: 20px !important;
    //     margin-left: 0px !important;
    // }
    .browse-content-main-with-search {
        width:100% !important;
        padding-left:20px !important;
        padding-right: 20px !important;
        margin-left: 0px !important;
    }
    .image-browse-item-book {
        height: 53px !important;
    }
    .image-browse-item {
        height: auto !important;
    }
    .xs-remove {
        display: none !important;
    }
    .xs-hide-content {
        overflow: hidden!important;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .text-component-row-padding {
        padding-top:8px !important;
        padding-bottom: 8px !important;
    }
    .text-component-row-heading {
        padding-top: 8px !important;
    }
    .xs-left-no-padding {
        padding-left:0px !important;
    }
    .xs-image-border {
        padding:0px !important;
        width: 45px !important;
    }
    .row-seperator {
        margin-left:60px !important;
    }
    .featured-image-center-resources {
        width:175px !important;
        height: 150px !important;
    }
}

.image-browse-item {
    height: auto !important;
    margin: auto;
    display: block;
}

.image-border-text {
    border: 1px solid #F5F5F5;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.05);
}

.image-browse-item-book {
    height: 194px;
    margin:auto;
    display: block;
}

.recommended-image-size {
    width:266px;
    height: 200px;
}

.recommended-text-size {
    width:266px;
    margin:auto;;
}

.browse-content-main {
    width:80%;
    margin:auto;
}

.browse-content-main-with-search {
    width:100%;
    margin:auto;
}

.featured-image-center-resources {
    cursor: pointer;
    overflow: hidden;
    border: 3px solid #F5F5F5;
    border-radius: 8px;
    width:245px;
    height: 200px;
    margin: auto;
}

.slick-prev:before {
    //font-family:'sw-font';
    font-family: "Open Sans", sans-serif;
    content: '\e835';
    color: #9b9b9b ;
}

.slick-next:before,
[dir=rtl] .slick-prev:before {
    //font-family:'sw-font';
  font-family: "Open Sans", sans-serif;
   content: '\e836';
   color: #9b9b9b ;
}

[dir=rtl] .slick-next:before {
    //font-family:'sw-font';
    font-family: "Open Sans", sans-serif;
    content: '\e835';
    color: #9b9b9b ;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: .0;
}

.navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 40px !important;
}

.scroll-independent {
    margin-top:60px;
}
.go-to-my-library {
    background-color: #46C56D;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 5px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 700;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    width: 140px;
    text-align:center;
    height:30px;
    margin-left: 85px;
    border:none;
}

.pop-over-style {
    height: 170px;
    width: 462px;
    padding: 30px 80px 30px 80px;
}

.md9class {
    width:calc(100% - 354px);
}

.open-file-button {
    color: #fff;
    background-color: #009BBD;
    border: 0;
    padding: 10px 40px;
    border-radius: 5px;
    -webkit-appearance: none;
}
.resource-container {
    padding-left: 35px;
    padding-right:35px;
}

.loader {
	padding: 20px 0px;
    font-size: 18px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    color: #009BBD;
    text-align: center;
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
}

.loading-image {
    width:25px;
    height:25px;
    margin-left: 10px
}

.bottom-margin-browse {
    overflow: auto;
}

@media not all and (min-resolution:.001dpcm) {
    .bottom-margin-browse {
        overflow: auto;
    }
}

.select-multiple {
    float: right;
    color: #009BBD;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    font-weight: 500;
    margin-right: 10px;
    cursor:pointer;
}

.add-select-multiple {
    float: right;
    color: #009BBD;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    font-weight: 500;
    margin-right: 10px;
    cursor:pointer;
    padding-right:10px;
    border-right: 1px solid #ddd;
}

.checkbox-icon {
    cursor: pointer;
    color: rgb(155, 155, 155);
    font-size: 27px;
    align-self: center;
 }

.selected-checkbox-icon {
    cursor: pointer;
    color: #46C56D;
    font-size: 27px;
    align-self: center;
}

.multi-select-popover {
    background-color: white;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    line-height: 50px;
    position:fixed;
    left:70%;
    top:10%;
    z-index: 1000;
    text-align: center;
    height: 150px;
    width: 400px;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.5);
    padding: 20px 20px 20px 20px;
    margin-right: 20px;
}

.image-center-resources-with-opacity {
    cursor: pointer;
    overflow-y: hidden;
    border: 3px solid #F5F5F5;
    border-radius: 8px;
    opacity: 0.6;
}

.resource-name-with-opacity {
    max-width: 90%;
    width: 90%;
    opacity: 0.6;
}

.go-to-my-library-add-multiple {
    background-color: #46C56D;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 5px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 700;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    width: 140px;
    text-align: center;
    height: 30px;
    margin-left: 28px;
    border: none;
    margin-bottom:20px;
}

.selected-checkbox-icon-add-multiple {
    cursor: pointer;
    color: #46C56D;
    font-size: 27px;
    align-self: center;
    float: left;
    margin-left: 30px;
}

.single-select-popover {
    background-color: white;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    line-height: 50px;
    position: fixed;
    right: 0px;
    top: 10%;
    z-index: 1000;
    text-align: center;
    height: 150px;
    width: 400px;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.5);
    padding: 20px 20px 20px 20px;
    margin-right: 20px;
}

.item-added {
    color: #444444;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 25px;
}

.add-to-planner-library {
    background-color: #3498DB;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 5px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 700;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    width: 130px;
    text-align: center;
    height: 30px;
    border: none;
    margin-left:23px;
}
.single-select-popover-failure {
    background-color: white;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    line-height: 20px;
    position: fixed;
    left: 70%;
    top: 10%;
    z-index: 1000;
    text-align: center;
    height: 100px;
    width: 300px;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
    padding: 10px 10px 10px 10px;
    margin-right: 20px;
}

.go-to-my-library-planner {
    background-color: #FFFFFF;
    color: #9B9B9B;
    border: 1px solid #9B9B9B;
    cursor: pointer;
    border-radius: 5px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 700;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    width: 130px;
    text-align: center;
    height: 30px;
    margin-left:23px;
}

.new-resources-single-item-container{
  padding: 20px;
  background-color: rgb(248, 248, 248);
  width: 100%;
  border-bottom: 1px solid #bdbdbd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
    transition:all;
    transition-duration: 200ms;
}

.new-resources-single-item-container:hover{
    background-color: #F2FAFB;
}

.new-resources-single-item-container-active{
  padding: 20px;
  background-color: #F2FAFB;
  width: 100%;
  border-bottom: 1px solid #bdbdbd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.accordion-title{
  margin-bottom: 0;
  font-size: 16px;
}

.MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root{
  font-size: 2.3rem
}

.MuiCollapse-root.MuiCollapse-vertical{
  max-height: 300px;
  overflow-y: scroll;
}

.new-single-item-left{
  border-radius: 6px;
  width: 49%;
}
.new-single-item-left-folder{
    border-radius: 6px;
    width: 100%;
}
.color-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 9px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  padding: 5px 3px;
}
.color-container.folder{
  background-color: #94D8E2;
}
.color-container.green{
  background-color: #D8F064;
}

.color-container.blue{
  background-color: #90E4FF;
}

.color-container.tirquise{
  background-color: #94D8E2;
}

.color-container.orange{
  background-color: #FEBD11;
}

.color-container.purple{
  background-color: #AD8ED9;
}

.color-container.pink{
  background-color: #EF9FB7;
}

.icons-left-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.icons-left-container span{
  margin: 0 7px 0 0;
  padding: 3px;
  border-radius: 6px;
  transition: all;
  transition-duration: 200ms;
}

.icons-left-container span:hover, .icons-left-container img:hover{
  cursor: pointer;
}

.icons-right-container{
  display: flex;
  flex-direction: row;
  align-items: center;
    position: relative;
}

.icons-right-container p{
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin: 0 7px 0 0;
    padding: 3px;
    border-radius: 6px;
    transition: all;
    transition-duration: 200ms;
}

.icons-right-container p:hover{
  cursor: pointer;
}
.flex-row{
  display: flex;
  flex-direction: row;
}
.new-single-item-right{
  width: 49%;
  position: relative;

  .MuiCollapse-root.MuiCollapse-vertical{
    max-height: none;
    overflow-y: hidden;
    margin-bottom: 20px;
  }
}
.new-single-item-right span, .new-single-item-right-text{
  font-family: "Open Sans", sans-serif;
}
.new-single-item-right span{
  position: absolute;
  bottom: 0px;
}
.new-single-item-image{
  width: 132px;
  height: 132px;
}
.new-single-item-image img{
  width: 132px;
  height: 132px;
    border-radius: 6px 6px 0 0;
    object-fit: contain !important;
}
.new-single-item-title{
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 0 10px;
}
.new-single-item-subtitle{
  font-size: 14px;
  font-weight: 400;
}
.new-single-item-grade{
  font-size: 16px;
  font-weight: 600;
}
.new-single-item-bottom-color-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px
}
.custom-filters-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 12px;
  margin-bottom: 10px;
}

.filters-disabled {
  opacity: 0.3;
  pointer-events: none;
}


.single-filter-container{
  border: 2px solid #33afca;
  border-radius: 6px;
  padding: 6px 8px 6px 8px;
  background-color: #fff;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  text-transform: capitalize;
  line-height: 14px;
  font-weight: 600;
  margin: 5px;
}
.single-filter-container-active{
  //border: 2px solid #33afca;
  //border-radius: 6px;
  //padding: 3px;
  //margin: 5px;
  //background-color: #94D8E2;
   border: 2px solid #33afca;
   border-radius: 6px;
   padding: 6px 8px 6px 8px;
   font-family: "Open Sans", sans-serif;
   text-transform: capitalize;
   line-height: 14px;
   font-weight: 600;
   margin: 5px;
   background-color: rgba(51, 175, 202, 0.2);
}
.single-filter-container:hover{
  background-color: rgba(51, 175, 202, 0.2);
  cursor: pointer;
}
.single-filter-container-active:hover{
  background-color: rgba(51, 175, 202, 0.2);
  cursor: pointer;
}
.single-filter-container p {
  margin: 0;
  padding: 0;
  color: #33afca;
}

.single-filter-container-active p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #33afca;
}

.new-responsive-nav-btns-container{
  display: flex;
  flex-wrap: wrap;
    padding: 0 12px;
    margin-top: 10px;
}

.new-single-responsive-btn{
  margin: 5px;
  padding: 6px 8px 6px 8px;
  border-radius: 6px;
  background-color: #fff;
  text-transform: capitalize;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}
.new-single-responsive-btn.green{
  border: 2px solid rgba(183, 210, 53, 1);
}
.new-single-responsive-btn.green:hover{
  background-color: rgba(183, 210, 53, 0.15);
  cursor: pointer;
}
.new-single-responsive-btn.green.active{
  background-color: rgba(183, 210, 53, 0.3);
}
.new-single-responsive-btn.green p{
  color: rgba(183, 210, 53, 1);
  margin: 0;
  font-size: 14px;
}

.new-single-responsive-btn.blue{
  border: 2px solid rgba(21, 190, 246, 1);
}
.new-single-responsive-btn.blue:hover{
  background-color: rgba(21, 190, 246, 0.15);
  cursor: pointer;
}
.new-single-responsive-btn.blue.active{
  background-color: rgba(21, 190, 246, 0.3);
}
.new-single-responsive-btn.blue p{
  color: rgba(21, 190, 246, 1);
  margin: 0;
  font-size: 14px;
}

.new-single-responsive-btn.purple{
  border: 2px solid rgba(114, 81, 161, 1);
}
.new-single-responsive-btn.purple:hover{
  background-color: rgba(114, 81, 161, 0.15);
  cursor: pointer;
}
.new-single-responsive-btn.purple.active{
  background-color: rgba(114, 81, 161, 0.3);
}
.new-single-responsive-btn.purple p{
  color: rgba(114, 81, 161, 1);
  margin: 0;
  font-size: 14px;
}

.new-single-responsive-btn.pink{
  border: 2px solid rgba(215, 99, 134, 1);
}
.new-single-responsive-btn.pink:hover{
  background-color: rgba(215, 99, 134, 0.15);
  cursor: pointer;
}
.new-single-responsive-btn.pink.active{
  background-color: rgba(215, 99, 134, 0.3);
}
.new-single-responsive-btn.pink p{
  color: rgba(215, 99, 134, 1);
  margin: 0;
  font-size: 14px;
}

.new-single-responsive-btn.tirquise{
  border: 2px solid rgba(57, 184, 200, 1);
}
.new-single-responsive-btn.tirquise:hover{
  background-color: rgba(57, 184, 200, 0.15);
  cursor: pointer;
}
.new-single-responsive-btn.tirquise.active{
  background-color: rgba(57, 184, 200, 0.3);
}
.new-single-responsive-btn.tirquise p{
  color: rgba(57, 184, 200, 1);
  margin: 0;
  font-size: 14px;
}

.new-single-responsive-btn.orange{
  border: 2px solid rgba(254, 168, 0, 1);
}
.new-single-responsive-btn.orange:hover{
  background-color: rgba(254, 168, 0, 0.15);
  cursor: pointer;
}
.new-single-responsive-btn.orange.active{
  background-color: rgba(254, 168, 0, 0.3);
}
.new-single-responsive-btn.orange p{
  color: rgba(254, 168, 0, 1);
  margin: 0;
  font-size: 14px;
}
.hover-cursor-pointer{
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  //background: #f5f5f5;
  background: #fff;
  box-shadow: none;
  border-radius: 0px;
  border-width: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  box-shadow: none;
  background: #cccccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  //background: #555;
}
.library-empty-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &.grey{
    background-color: rgb(248, 248, 248);
    padding-top: 50px;
  }
  img{
    margin: 20px 0;
  }
}
.cta-button {
  width: auto;
  padding: 12px;
  padding-left: 18px;
  padding-right: 18px;
  background-color: #33afca;
  border: 2px solid #33afca;
  color: white;
  border-radius: 6px;
  font-size: 21px;
  font-weight: 600;
}
.cta-button:hover {
  background-color: #f2fafb;
  color: #33afca;
  border: 2px solid #33afca;
}

.folder-texts-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 8px;
  .top {

    .new-single-item-title {
      margin-bottom: 6px;
      font-size: 18px;
      font-weight: 600;
      margin-top: 10px;
      margin-left: 0px;
    }
    .last-edit-text {
      margin: 0;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
    }
  }
  .contents-text {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

body{
  font-family: "Open Sans", sans-serif;
}

//{
//  name: 'writing',
//  primaryColor: 'rgba(21, 190, 246, 1)',
//  hoverColor: 'rgba(21, 190, 246, 0.15)',
//  activeColor: 'rgba(21, 190, 246, 0.3)',
//  active: false
//},
//{
//  name: 'language',
//  primaryColor: 'rgba(114, 81, 161, 1)',
//  hoverColor: 'rgba(114, 81, 161, 0.15)',
//  activeColor: 'rgba(114, 81, 161, 0.3)',
//  active: false
//},
//{
//  name: 'speaking-listening',
//  primaryColor: 'rgba(215, 99, 134, 1)',
//  hoverColor: 'rgba(215, 99, 134, 0.15)',
//  activeColor: 'rgba(215, 99, 134, 0.3)',
//  active: false
//}