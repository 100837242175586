@import "~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss";

/* styles for '...' */
.planner-item-title {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (2) */
  max-height: 2.4em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}
/* create the ... */
.planner-item-title:before {
  /* points in the end */
  content: "...";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
/* hide ... if we have text, which is less than or equal to max lines */
.planner-item-title:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: #e5f5f8;
}

/* styles for '...' */
.planner-item-details {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 13px;
  /* max-height = line-height (13px) * lines max number (2) */
  max-height: 26px;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: 0em;
  padding-right: 1em;
}
/* create the ... */
.planner-item-details:before {
  /* points in the end */
  content: "...";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
/* hide ... if we have text, which is less than or equal to max lines */
.planner-item-details:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: #e5f5f8;
}

.desc {
  font-size: 12px;
  font-weight: 300;
  line-height: 13px;
  color: #3c3c3c;
}

.my-font {
  font-size: 24px;
  font-weight: 100;
}

.user-circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.pencil-icon {
  width: 15px;
  height: 15px;
  filter: invert(35%) sepia(0%) saturate(0%) hue-rotate(194deg) brightness(96%)
    contrast(115%);
  margin-left: 5px;
}

.search-icon {
  color: #aeaeae;
  transform: rotate(90deg);
}

#myfont-color {
  color: Black;
  z-index: 3;
}

.nb {
  background-color: grey;
  color: white;
  height: 70px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.combined-shape {
  height: 2.93%;
  width: 2.2%;
  opacity: 0.5;
  transform: scaleX(-1);
  background-color: #ffffff;
}

.caret {
  display: none !important;
}

.browse {
  height: 22px;
  color: #ffffff;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
}

.browse-header {
  height: 60px;
}

.browse-padded {
  height: 22px;
  color: #ffffff;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
}

#navid {
  height: 70px;
  opacity: 0.9;
  background-color: #162c46;
}

.pad {
  padding: 15px;
}

.pad-left {
  padding-left: 15px;
}

.pad-top {
  padding-top: 15px;
}

.event-placeholder {
  border-style: dashed !important;
  border-top-left-radius: 10px;
  text-align: center;
  vertical-align: middle;
  padding-top: 10%;
  height: 100px;
  width: 100%;
}

.event-active {
  text-align: center;
  border-style: solid;
  border-radius: 10px;
  vertical-align: middle;
  padding-top: 10%;
  color: Black;
  height: 100px;
  width: 100%;
}

.event-selected {
  text-align: center;
  border-style: solid;
  border-radius: 10px;
  vertical-align: middle;
  padding-top: 10%;
  color: Black;
  height: 100px;
  width: 100%;
}

.event-active-row {
  padding: 10px;
}

table {
  border-collapse: separate !important;
  border-radius: 10px;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.add-padding {
  padding-top: 50px;
  padding-bottom: 100px;
}

.lesson-8-summarizin {
  height: 5.66%;
  width: 12.21%;
  color: #3c3c3c;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.icons {
  float: left;
  padding-top: 6px;
}

.menu-right-content {
  padding-left: 10px;
  overflow: hidden;
  margin-top: 15px;
}

.menu-right-content h2 {
  color: #529ed0;
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 0px !important;
}

.middle-comp h2 {
  font-size: 17px;
  color: #009bbd;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  margin-top: 9px;
  margin-bottom: 0px;
  line-height: 20px;
}

.menu-right-content span {
  color: #b2b2b2;
  line-height: 18px;
  font-size: 15px;
  white-space: normal;
}

.middle-comp span {
  color: #666666;
  line-height: 10px;
  font-size: 8px;
  white-space: normal;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
}

.middle-comp {
  overflow: hidden;
  padding-left: 6px;
  float: left;
}

.gallery-container {
  display: inline-block;
  width: 100%;
}

.gallery-container h2 {
  font-size: 42px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  color: #4a4a4a;
  margin-bottom: 0px;
}

.gallery-container h3 {
  font-size: 17px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  color: #4a4a4a;
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.gallery-container h4 {
  font-size: 12px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  color: #9b9b9b;
  margin-top: 0px;
  margin-bottom: 10px;
}

.gallery-container img {
  max-width: 100%;
}

.middle-comp-area .row {
  padding-left: 60px;
}

.middle-comp-area-lib .row {
  padding-left: 40px;
  padding-right: 40px;
}

.middle-comp-area .icons {
  padding-left: 0px;
}

.label-wrapper {
  padding-bottom: 10px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  line-height: 20px;
}

.label-wrapper h3 {
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  margin-top: 0px;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
}

.textBox-wrapper {
  padding-bottom: 10px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  line-height: 20px;
}

.textBox-wrapper h3 {
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
}

.form-wrapper-outer {
  /*padding-top: 50px;
    padding-bottom: 50px;*/
  padding-top: 0;
  padding-bottom: 10px;

  border: 1px solid #ccd2de;
  border-bottom-color: white;
  border-top-color: white;
}

.form-wrapper-inner {
  margin: 0 auto;
  width: 75%;
}

.view-grade .grade-round {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  vertical-align: middle;
  display: table-cell;
  font-weight: 800;
  font-size: 36px !important;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  text-align: center;
  color: #ffffff;
  font-size: 30px;
  margin-top: 20px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 0 rgba(0, 0, 0, 0.05);
}

.bell {
  position: relative;
}

.view-grade {
  padding-left: 30px;
}

.view-grade h2 {
  font-size: 42px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  color: #4a4a4a;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
}

.view-grade .red {
  background: #c50014;
}

.view-grade {
  padding-bottom: 80px;
  padding-left: 60px;
}

@media (max-width: 767px) {
  .view-grade .grade-round {
    margin: 10px auto;
  }

  .dashboard-footer {
    top: 0px;
  }

  .view-grade {
    padding-bottom: 150px;
  }
}
@media only screen and (max-device-width: 1280px) {
  .browse-page-right-container div .detailed-description div span img {
    height: 10vh !important;
  }

  .browse-page-right-container h4 {
    font-size: 12px !important;
  }

  .new-single-responsive-btn {
    padding: 3px 4px 3px 4px !important;
  }

  .new-single-responsive-btn p {
    font-size: 75% !important;
  }
  .single-filter-container {
    margin: 3px 4px 3px 4px !important;
  }

  .global-unit-searchbar {
    font-size: 75% !important;
  }

  .global-search-heading {
    font-size: 80% !important;
  }

  .collapsible-dropdown {
    padding: 3px 4px 3px 4px !important;
    font-size: 75% !important;
  }

  .new-single-item-image img {
    width: 100px !important;
    height: 100px !important;
  }

  .new-single-item-title {
    font-size: 80% !important;
  }

  .new-single-item-right {
    font-size: 75% !important;
  }

  .new-single-item-right span {
    bottom: 1vh !important;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .gallery-container h2 {
    font-size: 26px;
  }

  .gallery-container h3 {
    font-size: 17px;
    margin-top: 0px !important;
    margin-bottom: 0px;
  }

  .middle-comp {
    padding-left: 10px;
  }

  .middle-comp h2 {
    font-size: 17px;
  }

  .middle-comp span {
    line-height: 10px;
    font-size: 8px;
  }

  .view-grade .grade-round {
    width: 100px;
    height: 100px;
    font-size: 40px !important;
  }

  .col-md-3 {
    padding-right: 1% !important;
    padding-left: 0px !important;
    padding-bottom: 1.5%;
  }

  .gallery-container h4 {
    font-size: 12px;
  }

  .view-grade h2 {
    font-size: 26px;
  }

  .bg-light-blue {
    height: 60px !important;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .gallery-container h2 {
    font-size: 26px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    color: #4a4a4a;
    margin-top: 90px;
    margin-bottom: 0px;
  }

  .view-grade h2 {
    font-size: 26px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    color: #4a4a4a;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .gallery-container h3 {
    font-size: 17px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    color: #4a4a4a;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .gallery-container h4 {
    font-size: 12px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    color: #9b9b9b;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .middle-comp h2 {
    font-size: 17px;
    color: #009bbd;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    margin-top: 9px;
    margin-bottom: 0px;
    line-height: 20px;
  }

  .gallery-container {
    padding-left: 30px;
    overflow: hidden;
  }

  .view-grade {
    padding-bottom: 80px;
    padding-left: 30px;
  }

  .bg-light-blue {
    height: 60px !important;
    width: 266px;
    border-radius: 4px;
    background: rgb(0, 155, 189);
    background: rgba(0, 155, 189, 0.05);
    display: table;
    padding-left: 3px;
    padding-right: 3px;
  }

  .middle-comp-area .row {
    padding-left: 30px;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .gallery-container h3 {
    font-size: 17px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    color: #4a4a4a;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .gallery-container h4 {
    font-size: 12px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    color: #9b9b9b;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .middle-comp h2 {
    font-size: 17px;
    color: #009bbd;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    margin-top: 9px;
    margin-bottom: 0px;
    line-height: 20px;
  }

  .middle-comp span {
    color: #666666;
    line-height: 10px;
    font-size: 8px;
    white-space: normal;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  }
}

/*Laptop half screen--------*/
@media only screen and (min-width: 718px) and (max-width: 912px) {
  .gallery-container h2 {
    font-size: 32px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    color: #4a4a4a;
    margin-top: 90px;
    margin-bottom: 0px;
  }

  .gallery-container h3 {
    font-size: 17px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    color: #4a4a4a;
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .gallery-container h4 {
    font-size: 12px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    color: #9b9b9b;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .bg-light-blue {
    height: 60px !important;
    width: 100%;
    border-radius: 8px;
    background: rgb(0, 155, 189);
    background: rgba(0, 155, 189, 0.05);
    display: table;
  }

  .middle-comp h2 {
    font-size: 17px;
  }

  .middle-comp span {
    line-height: 10px;
    font-size: 8px;
  }

  .view-grade h2 {
    font-size: 32px;
    margin-top: 1.5%;
    margin-bottom: 3%;
  }
}

.bg-not-clickable {
  height: 60px;
  width: 266px;
  border-radius: 8px;
  background: #f2fafb;
  display: table;
  padding-left: 3px;
  padding-right: 3px;
  margin: auto;
}
.browse-navigation-container {
  display: flex;
  flex: 1;
  padding-top: 20px;
}
.browse-btn-spacer {
  display: flex;
  flex: 1;
}

.browse-btn-row-container {
  display: flex;
  flex: 4;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
  flex-wrap: wrap;
}

@media (max-width: 1350px) {
  .browse-btn-row-container {
    flex: 6;
  }
}

@media (max-width: 1200px) {
  .browse-btn-row-container {
    flex: 8;
  }
}

@media (max-width: 1150px) {
  .browse-btn-row-container {
    flex: 50;
  }
}

.library-btn-row-container {
  display: flex;
  flex: 4;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
  flex-wrap: wrap;
}

@media (max-width: 1800px) {
  .library-btn-row-container {
    flex: 6;
  }
}

@media (max-width: 1600px) {
  .library-btn-row-container {
    flex: 10;
  }
}

@media (max-width: 1450px) {
  .library-btn-row-container {
    flex: 50;
  }
}

.btn-right-container {
  display: flex;
  flex-direction: row;
}

.bg-not-clickable-new-home {
  display: flex;
  justify-content: center;
  align-content: center;
  //margin-right: 150px !important;
  //margin-left: -30px !important;
}

.browse-btn-row-new > a {
  margin-left: 20px;
}

.bg-not-clickable-new {
  //height: 50px;
  //width: 266px;
  border-radius: 6px;
  background-color: #f2fafb;
  //display: table;
  //padding-left:3px;
  //padding-right: 3px;
  margin: auto;
  padding: 7px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.bg-not-clickable-new:hover {
  box-shadow: 0 0 0 1px #33afca;
  transition: all;
  transition-duration: 200ms;
}

.bg-not-clickable-new.active {
  background-color: #33afca;
}

.bg-not-clickable-new.active:hover {
  box-shadow: 0 0 0 0 #33afca;
  opacity: 85%;
  transition: all;
  transition-duration: 200ms;
}

.bg-not-clickable-new h2 {
  font-size: 17px;
  color: #009bbd;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
  //line-height: 20px;
}
.bg-not-clickable-new.active h2 {
  color: #fff;
}
.icons-new {
  float: left;
  padding: 0;
  //padding-top:6px;
}
.icons-new img {
  width: 30px;
  margin-right: 10px;
}

// HOME BUTTON
.icons-new-home img {
  margin: 0 !important;
  height: 30px;
  width: 30px;
}

.icons-new .no-margin {
  margin: 0;
}
.table-cell-new {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.middle-comp-new {
  overflow: hidden;
}

.shared-notification {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  position: relative;
  left: auto;
  top: -48px;
  right: -143px;
  bottom: auto;
  z-index: 150;
  width: 18px;
  height: 18px;
  border-style: solid;
  border-width: 1px;
  border-color: #ff8878;
  border-radius: 10px;
  background-color: #f16855;
}

.shared-notification-text {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #fff;
}

//.middle-comp-new h2{
//  font-size: 17px;
//  color: #009BBD;
//  font-family: "ProximaNova";
//  margin-top: 0px;
//  margin-bottom: 0px;
//  //line-height: 20px;
//}

.bg-light-blue {
  height: 60px;
  width: 266px;
  border-radius: 8px;
  background: rgb(0, 155, 189);
  background: rgba(0, 155, 189, 0.05);
  display: table;
}

.bg-light-blue:hover {
  background: #f7ffff;
}

.notification {
  position: absolute;
  top: -4px;
  right: -5px;
  background: #ff321e;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #eaeaea;
  font-size: 12px;
  font-weight: bold;
}

.align-text {
  text-align: center;
}

.align-text-right {
  text-align: right;
}

.outer {
  width: 100%;
  display: table;
}

.inner {
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.table-cell-nav {
  display: table-cell;
  vertical-align: middle;
  padding-top: 5px;
  padding-bottom: 5px;
}

.col-md-3 {
  padding-right: 1% !important;
  padding-left: 0px !important;
  padding-bottom: 1.5%;
}

.col-md-2 {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 30px !important;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.line {
  box-sizing: border-box;
  /*height: 2px;*/
  /*border-top: 1px solid #CCD2DE;*/
  margin-bottom: 1.5%;
  margin-left: 40px;
  margin-right: 52px;
}

.line-bottom {
  box-sizing: border-box;
  height: 2px;
  border-top: 1px solid #ccd2de;
  margin-bottom: 1.5%;
  /*margin-left: 40px;*/
  margin-right: 20px;
  margin-top: 4px;
}

.dropdown-menu {
  min-width: 310px !important;
  border: 2px solid #f5f5f5 !important;
  border-radius: 18px !important;
  background-color: rgba(255, 255, 255, 1) !important;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.5) !important;
}

.dashboard-text-format {
  height: 26px;
  color: #666666;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 26px;
  padding-top: 17px;
  padding-bottom: 16px;
  margin-left: 17px;
}

#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#root > [data-reactroot] {
  /*height: 100%;*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /*overflow:hidden;*/
}

.dashboard-add-demo-icon {
  //font-family: "sw-font"
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 30px;
  padding-top: 16px;
  padding-right: 35px;
  padding-bottom: 15px;
  padding-left: 10px;
  color: #009bbd;
}

.dashboard-search-demo-icon {
  //font-family: "sw-font"
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 30px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 32px;
  height: 30px;
  padding-top: 12px;
  padding-right: 35px;
  padding-bottom: 15px;
  color: #9b9b9b;
}

.clock-demo-icon {
  //font-family: "sw-font"
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 7px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 7px;
  font-size: 14px;
  padding-top: 20px;
  padding-right: 18px;
  margin-bottom: 23px;
  color: #9b9b9b;
}

.rbc-time-content {
  /*display:none;*/
}

.rbc-row-content {
  z-index: 1;
}

.rbc-time-view {
  margin-bottom: 20px;
  border: 1px solid #cccccc;
  border-radius: 18px;
  background-color: #ffffff;
  padding-bottom: 14px;
}

.rbc-label {
  display: none;
}

.rbc-day-bg + .rbc-day-bg {
  border: none;
}

.rbc-time-header > .rbc-row > * + * {
  border: none !important;
}

.rbc-time-header > .rbc-row:first-child {
  border: none !important;
}

.rbc-time-header {
  margin-right: 12px;
  margin-left: 12px;
  margin-top: 4px;
  margin-bottom: 10px;
}

.rbc-today {
  color: #009bbd !important;
  background-color: white !important;
}

.rbc-header {
  color: #3c3c3c;
  vertical-align: middle;
  font-weight: bold;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 16px;
  text-align: right !important;
  min-height: 0;
  padding-right: 18px !important;
  padding-top: 10px !important;
  padding-bottom: 10px;
}

.my-event-class {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
}

.my-toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
}

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  white-space: inherit;
}

.rbc-header > a:hover {
  text-decoration: none;
  cursor: none;
}

.my-event-wrapper {
  color: #3c3c3c;
  font-family: "Open Sans", sans-serif;
  height: 100% !important;
  /*height:90px !important;*/
  margin: 5px 5px 5px 5px;
  padding: 10px 10px 10px 10px;
  background: rgb(0, 155, 189);
  background: rgba(0, 155, 189, 0.1);
}

.my-event-wrapper-new-item {
  background-color: white;
  color: #9b9b9b !important;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  /*height: 80% !important;*/
  margin: 5px 5px 5px 5px;
  border: 1px dashed #979797;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.title {
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: #3f3f3f;
  /*padding-top: 30px;*/
  /*padding-bottom: 30px;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*white-space: nowrap;*/
}

.title-add-item {
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.rectangle-1 {
  box-sizing: border-box;
  height: 30px;
  width: 118px;
  border: 1px solid #3498db;
  border-radius: 8px 0 0 8px;
  background-color: #009bbd;
}

.rbc-agenda-view table .rbc-agenda-time-cell {
  display: none !important;
}

.rbc-agenda-view table {
  border: none !important;
}

.rbc-agenda-view table tbody > tr > td + td {
  border: none !important;
}

.rbc-agenda-content td {
  margin-bottom: 10px;
  display: block;
}

.more-demo-icon {
  //font-family: "sw-font"
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  width: 16px;
  text-align: center;
  font-variant: normal;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  height: 4px;
  margin-top: 35px;
  float: right;
  color: #9b9b9b;
  margin-right: 12px;
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dont-show-item {
  display: none;
}

#layoutSidebar {
  padding-right: 0px !important;
}

.rbc-agenda-view {
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  flex: none !important;
}

#info-bar {
  background-color: white;
  height: 60px;
  border: 1px solid #ccd2de;
  width: 100%;
}

.schoolwide-demo-icon {
  //font-family: "sw-font"
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 15px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  height: 30px;
  padding-top: 21px;
  padding-right: 15px;
  padding-bottom: 15px;
  color: #9b9b9b;
}

.date-cell {
  background-color: #f5f5f5;
  box-shadow: none;
  border-radius: 4px 4px 0 0;
  text-align: center;
  /*margin-right: 10px;*/
  color: #666666;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  height: 23px;
  padding-top: 4px;
  /*margin-top:15px;*/
  /*margin-bottom: 20px;*/
  margin-right: 10px;

  //This increases the size of the clickable area of arrows. This can be limited to touch devices only.
  > span {
    position: relative;
  }

  > span:after {
    content: "";
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
  }
}

.rbc-agenda-view table td:empty {
  display: none !important;
}

.rbc-agenda-view table tbody > tr > td {
  padding: 0 !important;
  vertical-align: top;
}

.show-grid {
  padding-top: 15px;
}

.list-view-arrow-down {
  //font-family: "sw-font"
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 15px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 5px;
  color: #666666;
}

.books-demo-icon {
  //font-family: "sw-font"
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 15px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #9b9b9b;
}

.my-library {
  height: 25px;
  color: #666666;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  line-height: 25px;
  margin-left: 10px;
  font-weight: 600;
}

.recent-activity {
  color: #4a4a4a;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 26px;
}

.recommended-for-you {
  color: #4a4a4a;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 15px;
  padding-left: 3px;
}

.list-view-arrow-right {
  //font-family: "sw-font"
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 15px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 9px;
  color: #666666;
}

.ruler-pencil-demo-icon {
  //font-family: "sw-font"
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  /* width: 15px; */
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 28px;
  color: #009bbd;
}

.books-solid-demo-icon {
  //font-family: "sw-font"
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  /* width: 15px; */
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 28px;
  color: #009bbd;
}

.piechart-demo-icon {
  //font-family: "sw-font"
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  /* width: 15px; */
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 28px;
  color: #009bbd;
}

.grid-demo-icon {
  //font-family: "sw-font"
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  /* width: 15px; */
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 28px;
  color: #009bbd;
}

.rbc-agenda-view table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  display: none !important;
}

.rbc-day-slot .rbc-time-slot {
  border: none !important;
}

.rbc-time-content > * + * > * {
  border: none !important;
}

.rbc-time-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  border: none !important;
  overflow-y: auto;
  position: relative;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 1;
  left: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
  display: none !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event {
  background-color: white !important;
  border-radius: 0 !important;
}
.rbc-day-slot .rbc-event {
  border: none !important;
}

.rbc-time-view .rbc-allday-cell {
  display: none !important;
}

@media (min-width: 768px) {
  .rbc-timeslot-group {
    min-height: 130px !important;
  }
}

@media (min-width: 1024px) {
  .rbc-timeslot-group {
    min-height: 85px !important;
  }
}

.rbc-time-header > .rbc-row:last-child {
  display: none;
}

.rbc-time-content > .rbc-day-slot {
  margin-bottom: 15px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie-fixMinHeight {
    display: flex;
  }

  #page {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .calendar {
    margin-top: 90px;
    margin-left: 30px;
    margin-right: 30px;
    flex-grow: 1;
  }
}

.calendar {
  margin-top: 90px;
  margin-left: 30px;
  margin-right: 30px;
  height: 100%;
}

.browse-item-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #4a4a4a;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  line-height: 20px;
  padding-left: 3px;
  padding-right: 3px;
}

@media screen and (max-width: 767px) {
  .browse-item-name {
    padding-top: 5px !important;
    padding-left: 15px !important;
    font-size: 15px;
  }

  .browse-item-type {
    padding-bottom: 22px !important;
    margin-left: 92px !important;
    border-bottom: 1px solid #ccd2de;
  }

  .recommended-for-you {
    margin-top: 70px !important;
  }

  .browse-all {
    font-size: 30px;
    margin-right: 12px !important;
    color: #009bbd;
    cursor: pointer;
    margin-top: -42px !important;
    float: right;
  }

  .browse-header-link {
    padding-top: 10px !important;
    margin-left: 12px;
  }

  .navbar-nav .open .dropdown-menu {
    position: absolute !important;
  }

  .dropdown-menu {
    min-width: 225px !important;
  }
}

.browse-item-type {
  color: #9b9b9b;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  margin-top: 2px;
  padding-left: 3px;
  padding-right: 3px;
}

.browse-all {
  font-size: 30px;
  margin-right: 15px;
  margin-top: 10px;
  color: #009bbd;
  cursor: pointer;
}

.datepicker-position {
  right: 308px;
}

.planner-remove {
  color: #9b9b9b;
  font-size: 15px;
  float: right;
  cursor: pointer;
}

.planner-remove-list {
  color: #9b9b9b;
  font-size: 30px;
  float: right;
  cursor: pointer;
  padding: 5px;
  position: relative;
  z-index: 0;

  @media (max-width: $screen-xs-max) {
    font-size: 22px;
  }
}

.planner-item-link {
  width: 80%;
}

.browse-header-link {
  padding-top: 20px;
}

//Hide the drag-shadow on planner.
@media (pointer: none), (pointer: coarse), (hover: on-demand), (hover: none) {
  .rbc-time-slot.rbc-addons-dnd-over {
    opacity: 0 !important;
  }
}

.rbc-day-slot .rbc-event {
  z-index: 0 !important;
}

.shared-folder-items {
  border-bottom: 1px dotted #ddd;
  padding: 4px 0px;
  text-transform: capitalize;
}
.shared-folder-items:last-child {
  border-bottom: none;
}

.browse-page-right-container {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 10px;
  overflow-y: scroll;
  height: 75vh;
  padding-bottom: 30px;
}
.browse-page-right-container h4 {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
}
.browse-page-right-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 400 !important;
}
.browse-page-right-image {
  width: 100%;
  height: auto;
  background-color: yellow;
}
.browse-page-right-none {
  width: 100%;
}
.browse-page-right-none img {
  width: 50%;
  margin-left: 10%;
  margin-top: 30px;
}
.browse-page-right-none p {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
}

.browse-page-right-visit-button {
  padding: 3px 6px;
  border-style: solid;
  border-width: 1px;
  border-color: #94d8e2;
  border-radius: 6px;
  margin: 20px 0px;
}
.browse-page-right-visit-button:hover {
  background-color: #f2fafb;
}
.browse-page-right-visit-button:hover {
  cursor: pointer;
}
.browse-page-right-visit-button p {
  font-family: "Open Sans", sans-serif;
  color: #333;
  font-size: 16px;
  text-align: center;
  padding: 0;
  margin: 0;
}

.link-component:hover {
  cursor: pointer;
  text-decoration: underline;
}

#loaderDiv {
  width: 100%;
}

// Add to folder modal overlay behind content
.add-to-folder-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 8888;
  height: 100vh;
  width: 100vw;
  background: #00000070;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

// Modal content wrapper
.add-to-folder-modal-wrapper {
  position: relative;
  z-index: 9999;
  overflow: hidden;
  min-height: 100px;
  min-width: 35%;
  border-style: solid;
  border-width: 1px;
  border-color: #33afca;
  border-radius: 6px;
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  cursor: default;
}

.share-modal-wrapper {
  position: relative;
  z-index: 9999;
  overflow: visible;
  width: 40%;
  height: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #33afca;
  border-radius: 6px;
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  cursor: default;
}

// Modal header
.add-to-folder-modal-header {
  width: 100%;
  padding: 9px 18px;
  background: #f2fafb;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4f4f4f;
  font-size: 19px;
}

.add-to-folder-modal-header-text {
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif !important;
  font-size: 21px !important;
  font-weight: 400 !important;
}

.add-to-folder-modal-header-text p {
  margin: 0 !important;
  padding-left: 8px !important;
}

// Content
.add-to-folder-modal-content {
  margin: 48px;
  position: relative;
  background: #f5f5f5;
  border-radius: 6px;
  height: 350px;
  overflow-y: scroll;
}

.add-to-folder-modal-close-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
}

.add-to-folder-modal-close-btn:hover {
  opacity: 70%;
  transition: all;
  transition-duration: 200ms;
}

.add-to-folder-modal-close-btn img {
  height: 10px;
  width: 10px;
  margin-right: 7px;
}

.add-to-folder-modal-confirm-button {
  border-radius: 6px;
  box-shadow: 0 0 0 0 #33afca;
  background-color: #33afca;
  padding: 7px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //margin: 0 30px 30px 30px;
  margin: 0 48px 30px 15px;
  color: white;
  cursor: pointer;
  width: fit-content;
  position: relative;
  float: right;
}

.add-to-folder-modal-confirm-button-disabled {
  border-radius: 6px;
  box-shadow: 0 0 0 0 #33afca;
  background-color: #33afca60;
  color: white;
  padding: 7px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //margin: 0 30px 30px 30px;
  margin: 0 48px 30px 15px;
  cursor: not-allowed;
  pointer-events: none;
  width: fit-content;
  position: relative;
  float: right;
}

.delete-modal-confirm-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.delete-modal-confirm-button span {
  border-radius: 6px;
  box-shadow: 0 0 0 0 #33afca;
  background-color: #33afca;
  padding: 7px 15px;
  color: white;
  cursor: pointer;
}

.create-folder-modal-container {
  position: sticky;
  top: 0;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  z-index: 9999;
}

.create-folder-modal-button {
  height: 56px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid slategray;
  width: 100%;
}

.create-folder-modal-button-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid slategray;
  width: 100%;
}

.create-folder-modal-button {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
}

.create-folder-modal-button span {
  font-size: 35px !important;
  margin-right: 8px;
}

.add-to-folder-modal-confirm-button:hover {
  opacity: 85%;
  transition: all;
  transition-duration: 200ms;
}

.add-to-folder-modal-confirm-button img {
  margin-right: 5px;
}

.add-to-folder-modal-single-item {
  display: flex;
  position: relative;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid slategray;
}

.add-to-folder-modal-single-item-last {
  border-bottom: 0 !important;
}

.add-to-folder-modal-single-item:hover {
  //background: #F2FAFB;
  cursor: pointer;
  transition: all;
  transition-duration: 200ms;
}

.add-to-folder-modal-single-item p {
  margin-bottom: 0;
  padding: 10px;
  font-family: "Open Sans", serif;
  font-size: 18px;
}

.add-to-folder-modal-input {
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: 0 20px;
  position: relative;
  top: 0;
  height: 35px;
  z-index: 9999;
  border-bottom: 0 !important;
  &.rename {
    margin: 0;
    margin-bottom: 6px;
  }
}

.no-border {
  transition: all;
  transition-duration: 200ms;
  width: 100%;
  border-bottom: 0 !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px !important;
  line-height: 18px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.no-border-folder {
  transition: all;
  transition-duration: 200ms;
  border-bottom: 0 !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px !important;
  line-height: 18px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.add-to-folder-modal-input-textfield {
  height: 35px;
  border-radius: 6px;
  margin: 0 10px;
  width: 170px;
  &.rename {
    margin: 0;
  }
}

@media screen and (max-width: 1280px) {
  .add-to-folder-modal-input-textfield.rename {
    width: 130px;
  }
}

.add-to-folder-modal-input-bottom {
  display: flex;
  background: rgb(248, 248, 248);
  align-items: center;
  padding: 20px 0;
  position: relative;
  top: 0;
  height: auto;
  z-index: 9999;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  line-height: 24px;
  font-weight: 400;
}

.add-to-folder-modal-input-bottom:hover {
  color: rgba(0, 0, 0, 0.5);
  transition: all;
  transition-duration: 200ms;
}

.add-to-folder-modal-input-textfield-bottom {
  height: 28px;
  border-radius: 6px;
  margin: 0 10px;
}

.select-multiple-resources {
  color: #333;
  display: inline-block;
  border: 1px solid #94d8e2;
  border-radius: 6px;
  z-index: 9999;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin-right: 10px;
  padding: 0.5px 8px 0.5px 3px !important;
  cursor: pointer;
  transition: all;
  transition-duration: 200ms;
}

.select-multiple-resources:hover {
  background-color: #f2fafb;
}

.select-multiple-resources-icons {
  border: 1px solid #94d8e2;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.select-multiple-resources-icons p {
  padding: 2px 8px 2px 8px !important;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: left;
}

.select-multiple-button-active p:hover {
  text-decoration: underline;
}

#addToFolder-p-tag,
#remove-p-tag,
#addToLibrary-p-tag {
  display: flex;
  align-items: center;
}

#addToLibrary-p-tag,
#addToFolder-p-tag {
  margin-right: 3px;
}

#addToFolder-p-tag:hover,
#remove-p-tag:hover,
#addToLibrary-p-tag:hover {
  transition: all;
  transition-duration: 200ms;
  background-color: #f2fafb;
  text-decoration: none;
}

.select-multiple-resources-icons img {
  width: 22px;
  height: 22px;
}

.select-multiple-button {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  width: 100%;
  text-transform: none !important;
}

.select-multiple-button-active {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  width: 100%;
  gap: 12px;
  text-transform: none !important;
}

.select-multiple-button-active p {
  cursor: pointer;
  margin-bottom: 0 !important;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: left;
}

.share-modal-content {
  padding: 20px;
  display: grid;
  gap: 20px;
  align-items: center;
}

.share-modal-confirm-button {
  border-radius: 6px;
  box-shadow: 0 0 0 0 #33afca;
  background-color: #33afca;
  padding: 7px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  width: fit-content;
  justify-self: end;
}

.share-modal-confirm-button-disabled {
  border-radius: 6px;
  box-shadow: 0 0 0 0 #33afca;
  background-color: #33afca60;
  padding: 7px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: not-allowed;
  pointer-events: none;
  width: fit-content;
  justify-self: end;
}

.autocomplete-dropdown {
  top: 100px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #33afca;
  border-radius: 6px;
  position: absolute;
  width: 80%;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-height: 200px;
  z-index: 99999;
  overflow-x: auto;
}

.autocomplete-dropdown li {
  padding: 0.5rem;
  cursor: pointer;
}

.autocomplete-dropdown li:hover {
  background-color: #f2fafb;
}

.no-suggestions {
  position: absolute;
  top: 100px;
  font-size: smaller;
  color: red;
}

.share-modal-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    display: inline-block;
    padding-top: 10px;
  }

  input {
    width: 85%;
    border-radius: 6px;
    border: 1px solid #33afca;
    padding: 5px 10px;
  }
}

.delete-modal-content {
  padding: 20px;
  font-size: 18px;
  line-height: 13px;
  font-weight: 600;
  text-align: center;
}

.new-resources-single-item-container-grayed {
  opacity: 40%;
  padding: 20px;
  background-color: #f2fafb;
  width: 100%;
  border-bottom: 1px solid #bdbdbd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all;
  transition-duration: 200ms;
}

.icons-left-container input {
  margin: 10px 30px !important;
}
.multiselect-resource-heading {
  position: sticky;
  top: 0;
  background: white;
  z-index: 999;
  display: flex;
  justify-content: end !important;
  align-items: center;
}

@media screen and (max-width: 1280px) {
  .multiselect-resource-heading {
    flex-wrap: wrap;
    justify-content: start !important;
  }

  .select-multiple-button {
    justify-content: space-between;
  }
}

.select-multiple-button a,
.select-multiple-button-active a {
  display: inline-block;
  color: #d94e3b;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  text-decoration: underline;
  margin-right: 10px;
}

.select-multiple-button a:hover,
.select-multiple-button-active a:hover {
  color: #d94e3b;
  cursor: pointer;
  text-decoration: underline !important;
}

.select-multiple-button img,
.select-multiple-button-active img {
  margin-right: 5px;
}

.multiselect-breadcrumbs {
  padding: 0 !important;
  white-space: nowrap;
  width: fit-content;
  margin-left: 5px;
}

.multiselect-breadcrumbs a {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #4f4f4f;
  text-decoration: none;
}

.multiselect-breadcrumbs span {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #4f4f4f;
}

.multiselect-breadcrumbs a:hover {
  color: #4f4f4f;
  text-decoration: underline;
}

.multiselect-breadcrumbs img {
  margin-top: -3px;
}

.checkbox-multiselect-wrapper {
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
}

.checkbox-multiselect-wrapper p {
  display: inline-block;
  color: white;
  margin: 0 !important;
  margin-left: -15px !important;
}

.checkbox-multiselect {
  transform: scale(1.3);
  accent-color: #ffffff;
}

.checkbox-multiselect:checked:after {
  color: transparent;
  box-shadow: none !important;
}
