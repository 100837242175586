$joyride-header-color: rgb(0, 155, 189);
$joyride-header-border-color: #CACACA;
$joyride-tooltip-color: #4A4A4A;
$joyride-close-visible: false;
$joyride-tooltip-width: (250px, 400px, 650px);
$joyride-skip-button-color: #AAA;
$joyride-hole-border-radius: 11px;
$joyride-tooltip-border-radius: 11px;
$joyride-button-border-radius: 11px;

//@import '~react-joyride/lib/react-joyride';

.btn-tour {
  width: 200px;
  height: 70px;
  border-radius: 4px;
  background-color: #888888;
}

.assistant {
  background: url(../images//assistant.PNG);
}

.joyride-overlay {
  height: 100% !important;
}

.joyride-tooltip__header {
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
}

.joyride-tooltip__main {
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: '28px';
  line-height: '41px';

  a {
    color: rgb(0, 155, 189);
  }

  a.green-a-text {
    color: #46C56D;
  }

  a:hover {
    color: rgb(0, 155, 189);
  }

  a.green-a-text:hover {
    color: #46C56D;
  }

  a.red-a-text {
    color: #D0021B;
  }

  a.red-a-text:hover {
    color: #D0021B;
  }
}

.joyride-tooltip__footer {
  clear: both;
  padding-top: 10px;

  .joyride-tooltip__button {
    padding: 10px;
    border: solid 1px #AAA;
    margin-top: 15px;
  }

  .joyride-tooltip__button--skip {
    border-radius: 11px;
  }

}

//DEV DISABLE JOYRIDE BEACON
.joyride-beacon {
  display: none;
}

#start-tour-modal a, #start-tour-modal a:hover {
  color: #838288;
}

#disabled-tour-popover-target {
  color: #7e898b;
}
#disabled-tour-popover-target:hover {
  color: #7e898b;
}

@for $i from 1 through 40 {
  .mb-#{$i} {
      margin-bottom: 1px * $i !important;
  }
  .mt-#{$i} {
      margin-top: 1px * $i !important;
  }
  .mr-#{$i} {
      margin-right: 1px * $i !important;
  }
  .ml-#{$i} {
      margin-left: 1px * $i !important;
  }
}


#joyride-selector-add-unit {
  width: 45px;
  height: 45px;
  position: absolute;
  bottom: 10px;
  right: 141px;
}

#joyride-selector-icon-checkbox {
  width: 45px;
  height: 45px;
  position: absolute;
  bottom: -3px;
  right: 133px;
}
.custom-modal{
  margin:3rem;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body{
  font-family: "Open Sans", sans-serif;
}
