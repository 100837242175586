.image-size-in-books {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 45px;
    height: 60px;
    float: left;
    box-shadow: 0 0.5px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    border: 1.5px solid #F5F5F5;
    border-radius: 4px;
    cursor: pointer
}
.books-tab {
    box-sizing: border-box;
    height: 30px;
    /* width: 118px; */
    border-top: 1px solid #9B9B9B;
    border-left: 1px solid #9B9B9B;
    border-bottom: 1px solid #9B9B9B;
    background-color: #009BBD;
    padding: 8px 28px 8px 28px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    line-height: 14px;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
}


.remove-padding-bottom{
    .col-md-2{
        padding-bottom: 0px !important;
    }
}
.default_link_button {
    border-radius: 0;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
}
.change-height{
    margin-bottom:30px;
}
.image-size-in-books-with-opacity {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 45px;
    height: 60px;
    float: left;
    box-shadow: 0 0.5px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    border: 1.5px solid #F5F5F5;
    border-radius: 4px;
    cursor: pointer;
    opacity:0.6;
}

.blur-book{
    opacity: 0.6;
}
.show-book{
    opacity:1;
}

.book-icon{
    font-size:20px;
    padding:20px;
}

.begin-reading{
    border-radius: 4px;
    background-color: #009BBD;
    color: #FFFFFF;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
    border: none;
    width: 100%;
    padding: 10px;
}

.book-title{
    color: #4A4A4A;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    margin-top:20px;
}

.Collapsible {
  background-color: white; 
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0; 
}
.Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; 
}
.Collapsible__contentInner p:last-child {
    margin-bottom: 0; 
}
.Collapsible__trigger {
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: #333333;
  text-align:center;
  position: relative;
  border: 1px solid white;
  border-radius:10px !important;
  font-size:16px;
  padding: 10px;
  color: white;  
}
.borrow{
    .Collapsible__trigger {     
        background: #59BF18;
    } 
}
.borrow-open{
    .Collapsible__trigger {     
        background: #59BF18;
    } 
}
.buy{
    margin-top:8px;
    .Collapsible__trigger {     
      background: #289bbc;
    }
}

.buy-open{
    margin-top:2px;
    .Collapsible__trigger {     
      background: #289bbc;
    }
}

.Collapsible__trigger:after {
    /*font-family: 'Font Awesome 5 Free'; 
    font-weight: 900;
    content: '\f107';*/
    content:'';
    background-image: url(../icons/PNG/down_arrow_icon.png);
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    height: 18px;
    background-size: 100%;
    width: 25px;
    /*transform: rotateZ(180deg);*/
    transition-duration: 300ms;
    background-repeat: no-repeat; 
}
.Collapsible__trigger.is-open:after {
    transform: rotateZ(0deg); 
}

.Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; 
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; 
}

.CustomTriggerCSS--open {
  background-color: darkslateblue; 
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black; 
}