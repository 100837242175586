.trialModal {
  bottom: 82px !important;
  top: auto !important;
  left: auto !important;
  right: 60px !important;
  position: absolute !important;
}

.trialModalFooterContactUsText {
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-style: normal;
  color: #4A90E2 !important;
  font-size: 15px;
  text-align: left;
  padding-bottom: 6px;
  padding-right: 18px;
  padding-top: 1.5em;
  position: relative;
  float: left;
}

.trialModalContactUsButton {
  float: right;
  color: white;
  height: 3.5em !important;
  width: 113.68px;
  border-radius: 22px !important;
  background-color: #4A90E2 !important;

  &:hover {
    color: white !important;
    opacity: 0.8 !important;
  }
}

.trialModalWelcomeBackSpan {
  color: #009BBD;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.trialCloseIcon {
  color: #BEBEBE;
}

.trialModalClose {
  float: right;
  color: #BEBEBE;
}

.trialBodyText {
  color: #4A4A4A;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 17px;
  line-height: 20px;
}

.trialAvatarContainer {
  width: 8em;
  float: right;
  img {
    height: 175px;
  }
}
