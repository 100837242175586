.loading-panel {
  width: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  background-color: #ffffff;
  padding: 30px;
  border: 2px solid #fff;
  border-radius: 10px;
}
.auth-wrapper{
  background-color: #b5b0b0;
  height: 100vh;
}
h4{
  margin-top:0 !important;
  font-weight:bold !important;
}
.loading-text p{
  font-size: 17px;
  letter-spacing: 0.5px;
}
.loading-text.warning{
  margin-top: 5%;
}
.loading-text.warning p{
  color: #ff0000;
}

//Loading Logo Style
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #cfd4d4;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: 0;
}
.format-button{
  background-color: rgb(0, 155, 189);
    color: white;
    cursor: pointer;
    width: 50%;
    font-size: 15px;
    line-height: 10px;
    padding: 14px 10px;
    margin: 8px 0px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 6px;
}
@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
}
