.trail-heading{
	border-radius: 4px;
	//background-color: #25BEDF;
	color: #FFFFFF;
	font-family: "ProximaNova";
	font-size: 23px;
	font-weight: bold;
	line-height: 23px;
    padding:9px;
	text-align: center;
}

.trail-page-heading{
	color: #3F3F3F;
	font-family: "ProximaNova";
	font-size: 35px;
	font-weight: 700;
	line-height: 42px;
}

.trail-page-sub-heading{
    font-size: 25px;
    font-weight: 400;
}

.trail-page-sub-data{
	color: #3F3F3F;
	font-family: "ProximaNova";
	font-size: 15px;
	line-height: 18px;
    padding-top: 1.9%;
}

.go-back{
	padding-top:23px;
	color: #3F3F3F;
	font-family: "ProximaNova";
	font-size: 12px;
	line-height: 29px;
	text-align: center;
	cursor: pointer;
}

.next-button{
	height: 42px;
	width: 185px;
	border-radius: 3px;
    color: #FFFFFF;
    border: none;
	background-color: #FF7A5A;
}

.next-button-trail{
    padding-top:50px;
    text-align: center;
}

.two-column-display{
	column-count: 2;
	margin-top:35px;
}

.checkbox-item-trail{
	padding-bottom: 20px;
}

.referral-button{
	height: 42px;
	width: 105px;
	border: 1.5px solid rgba(63,63,63,0.38);
	border-radius: 3px;
	font-family: "ProximaNova";
	text-align: center;
	padding-top:10px;
	cursor: pointer;
	margin-bottom:12px;
}

.button-padding-referral{
	margin-top: 35px;
	margin-bottom:35px;
	overflow: auto;
	column-count: 5;
}

@media only screen and (max-width: 1233px) {
    .button-padding-referral{
		margin-top: 35px;
		margin-bottom:35px;
		overflow: auto;
		column-count: 3;
	}
}

@media only screen and (max-width: 728px) {
    .button-padding-referral{
		margin-top: 35px;
		margin-bottom:35px;
		overflow: auto;
		column-count: 1;
	}
}
.referral-button-value{
	color: #3F3F3F;
	opacity: 0.5;
	font-size: 12px;
}

.trail-image-size{
	text-align: center;
	height: 180px;
}

.color-change{
	margin: auto;
	height: 15px;
	width:15px;
	background: #D9D9D9;
	border-radius: 50%;
	display: inline-block;
}

.page-change-item{
	padding:8px;
}

.page-change{
	display: flex;
	justify-content: center;
}

.trail-content{
	padding-left:15%;
	padding-right:15%;
	padding-top:50px
}

.trail-head-name{
	padding-left: 2%;
	padding-top: 3%;
	width: 300px;
}

.trail-page-content{
	width: 67.38%;
	margin: auto;
}

.trail-page-data{
	position: absolute;
	width:67.38%;
	background: #FFFFFF;
	top:0;
	bottom: 0;
	overflow: auto;
}

#trail-page-background{
	background: #25BEDF;
	overflow-y: auto;
	font-family: "ProximaNova";
}

input#checkbox-trial{
    position: fixed !important;
}

.trial-dropdown{
  height: 33px;
  width: 100%;
  border-width:0px 0px 1px 0px;
  border-color:#3F3F3F;
  font-family: "ProximaNova";
  font-size: 14px;
  color: #000;
}

.trail-dropdown-error{
  height: 33px;
  border-width:0px 0px 1px 0px;
  border-color:#C44545;
  width: 100%;
  font-family: "ProximaNova";
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
}

.trail-place-label {
  font-family:"ProximaNova";
  font-size: 11px;
  color: #3F3F3F;
}

.trail-place{
	width:100%;
	margin-top: 6px;
    margin-bottom: 10px;
}