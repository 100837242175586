@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
.summary-heading {
	color: #999999;
	//font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 13px;
}

.summary-content {
	color: #444444;
	//font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
	font-size: 15px;
	font-weight: 500;
	line-height: 18.5px;
}

.summary-standard{
	@extend .summary-content;
	font-weight: bold
}

.lesson-button {
	height: 30px;
	width: 100.18px;
	border-radius: 2px 2px 0 0;
	background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0.5) 100%);
}

.lesson-button-text {
	height: 13.5px;
	color: #9B9B9B;
	//font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
	font-size: 11px;
	font-weight: 600;
	line-height: 13.5px;
	text-align: center;
}

.lesson-header {
	height: 26px;
	width: 512px;
	color: #4A4A4A;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 21px;
	font-weight: bold;
	line-height: 26px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #4A4A4A;
}


.header-icon-detailed{
    font-size: 30px;
    color: #009BBD;
    margin: -5px 6px 10px 8px;
	cursor: pointer;
	line-height: 20px;
}

@media screen and (max-width: 767px) {
	.lesson-header {
		font-size: 17px !important;
		padding-left: 0px !important;
		float:left !important;
	}
	.header-icon-detailed{
		font-size: 25px !important;
		margin:-4px 0px 4px 0px !important;
	}
	.info-demo-icon{
		font-size: 25px !important;
	}
	.browse-demo-icon{
		font-size: 25px !important;
	}
	.header-vertical-line-detailed {
		border-left: 1px solid  #D8D8D8;
		padding-left : 3px !important;
		padding-right : 8px !important;
		float: right;
	}
/* Changed this to from padding-right to margin-right so the cursor: pointer only takes
effect over the icon and not the whitespace next to it*/
	.lesson-header-icons{
		margin-right : 4px !important;
	}
	#summary-row{
		//padding-top: 20px !important;
		padding-top: 40px !important;
		padding-bottom: 20px !important;
	}
}

@media (max-width: $screen-xs-max){
	.lesson-to-unit-content{
		padding-top: 90px;
		width: 100% !important;
		padding-left: 10px;
		padding-right: 10px;
		margin: auto;
	}
}
.text-overflow-wrap{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

}

.header-vertical-line-detailed {
  border-left: 1px solid  #D8D8D8;
  padding-left : 15px;
  padding-right : 15px;
  float: right;
}

.header-vertical-line {
  border-left: 1px solid  #D8D8D8;
  padding-left : 15px;
  padding-right : 15px;
  float: right;
}
/* Changed this to from padding-right to margin-right so the cursor: pointer only takes
effect over the icon and not the whitespace next to it*/
.lesson-header-icons{
  margin-right : 20px;
  //float: right;
	cursor: pointer;

	.icon-icon-reader-circle-solid {
		width: 26px;
		height: 26px;
		line-height: 1em;
		text-align: center;
		color: #46C56D;
		font-size: 25px;

		@media (min-width: 768px){
			display: none;
		}
	}

	.icon-icon-documents {
		width: 26px;
		height: 26px;
		line-height: 1em;
		text-align: center;
		color: #46C56D;
		font-size: 22px;

		@media (min-width: 768px){
			display: none;
		}
	}
}

.icon-blue {
	color: #009BBD !important;
	cursor: pointer;
}

.header-icon-test{
	color: #9B9B9B;
    font-size: 30px;
    float: right;
    padding-top: 8px;
}

.lesson-name {
	width: 113.1px;
	color: #999999;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 11px;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 13.5px;
}

.lesson-description{
	@extend .lesson-name;
	width:68px;
}
.general-word-style{
	color: #4A4A4A;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 15px;
	line-height: 18.5px;
}

.lessons-padding{
	margin-left: 0px;
	margin-right: 0px;
	padding-top:5px;
}

#summary-row{
	padding-top: 35px;
    padding-bottom: 35px;
    vertical-align: middle;
}

.main-seperator{
	box-sizing: border-box;
	height: 1px;
	border-bottom: 1px solid #979797;
	opacity: 0.5;
}

.text-padding{
	@extend .general-word-style;
	padding: 20px 0 20px 0;
}

.lesson-text-padding{
	@extend .general-word-style;
	padding: 20px 30px 20px 0;

}

#bcol-padding{
	padding-bottom: 0px !important;
}

.go-to-my-library-lessons {
	 background-color: #FFFFFF;
	 color: #9B9B9B;
	 border: 1px solid #9B9B9B;
	 cursor: pointer;
	 border-radius: 5px;
	 font-size: 11px;
	 line-height: 14px;
	 font-weight: 700;
	 //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	 width: 130px;
	 text-align:center;
	height:30px;
	margin-right: 20px;
 }

.add-to-planner{
	background-color: #3498DB;
	color: #FFFFFF;
	cursor: pointer;
	border-radius: 5px;
	font-size: 11px;
	line-height: 14px;
	font-weight: 700;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	width: 130px;
	text-align:center;
	height:30px;
	border:none;
	margin-right: 20px;
}

.lesson-added{
	color: #444444;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 14px;
	line-height: 17px;
	margin-bottom:40px;
}

.lesson-to-unit-heading {
	color: #4A4A4A;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 17px;
	font-weight: bold;
	line-height: 20px;
	text-align: center;
	padding-top: 20px;
}

.lesson-to-unit-header {
	box-sizing: border-box;
	height: 60px;
	width: 100%;
	border: 1px solid #CCD2DE;
	background-color: #FAFAFA;
	position: fixed;
	z-index:2
}

.searchbar-heading {
	color: #666666;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 11px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 13.5px;
	padding-bottom: 5px;
}

.lesson-to-unit-content{
	padding-top: 90px;
	width: 50%;
	margin: auto;
}

.units-lesson-content{
	width: 50%;
	margin: auto;
}

.lesson-units-number{
	margin-bottom: 10px;
	color: #9B9B9B;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 20px;
}

.unit-searchbar{
	height: 35px;
	width: 100%;
	border: 1px solid #CCCCCC;
	border-radius: 4px;
	background-color: #FFFFFF;
	content: '\e82c';
	padding-left: 25px;
}

.lesson-unit-searchbar{
	@extend .unit-searchbar;
	padding-left:35px;
}


.global-unit-searchbar {
    height: 35px;
    width: 100%;
	// Changed border to blue as in design
	margin-bottom: 0px;
	border-style: solid;
	border-width: 2px;
	border-color: #94d8e2;
	border-radius: 6px;
	background-position: 3px 50%;
	background-size: auto;
	background-repeat: no-repeat;
	//font-family: 'ProximaNova', sans-serif;
    font-family: "Open Sans", sans-serif;
	font-size: 16px;
	line-height: 16px;
	font-weight: 600;
    background-color: #FFFFFF;
    padding-left: 38px;
}

.add-unit-footer{
	box-sizing: border-box;
	height: 60px;
	width: 100%;
	border: 1px solid #CCD2DE;
	background-color: #FAFAFA;
	bottom: 0;
	position: fixed;

}

.cancel-lesson-unit{
	height: 30px;
	width: 130px;
	border: 1px solid #9B9B9B;
	border-radius: 4px;
	color: #9B9B9B;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 11px;
	font-weight: bold;
	line-height: 13px;
	background-color: #FAFAFA;
	text-align: center;

}

.button-regular-left{
	padding: 15px 0 15px 20px;
	float: left;
}

.button-regular-right{
	padding: 15px 20px 15px 0;
	float: right;
}

.assign-lesson-unit{
	height: 30px;
	width: 130px;
	border: 1px solid #46C56D;
	border-radius: 4px;
	background-color: #46C56D;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 11px;
	font-weight: bold;
	line-height: 13px;
	text-align: center;
	color:#FFFFFF;

}

.lesson-unit-border{
	border-top: 1px solid #CCD2DE;
    margin-left: 90px !important;
    margin-right: 1px !important;
}

.searchbar-unit{
	padding-bottom: 20px;
}

.detail-wrapper{
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	overflow-y: auto;
}

.detail-holder{

}
