@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
$swf-blue: rgb(0, 155, 189);
$picture-password-img-size: 128px;
$picture-password-img-size-small: 105px;

.root_with_overflowy {
  position: absolute;
  bottom: 70px;
  top: 60px;
  width: 100%;
  overflow-y: auto;
  //-webkit-overflow-scrolling: touch;
}

.root_without_overflowy {
  position: absolute;
  bottom: 75px;
  top: 0px;
  width: 100%;
  overflow-y: hidden;
}

@media (max-width: $screen-sm-max){

  .root_without_overflowy, .root_mobile_responsive_btns {
    bottom: 110px;
  }

  .toggle-resource {
    padding: 5px !important;
  }

  .two-header-btns {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  //Make menu full size on mobile
  .menu {
    & > div {
      width: 100vw;

      &.right {
        right: -100vw;

        &.visible {
          transform: translate3d(-100vw, 0, 0);
        }
      }
    }
  }

  //Move "Select Multiple" to the right.
  .select-multiple {
    padding-top: 5px;
    padding-bottom: 10px;
    margin-right: 15px;
  }
  .add-select-multiple {
    padding-top: 5px;
    padding-bottom: 10px;
  }

  //Specific, working size for mobile Featured sliders
  // .featured-image-center-resources {
  //   width: 223px;
  //   height: 170px;
  //   .image-container {
  //     width: 100%;
  //     height: auto;
  //   }
  // }

  //LoginComponent issues
  #root > [data-reactroot] {
    position: fixed !important;
  }

  .bg-color {
    position: static;
    height: 100%;
    overflow-y: auto;
  }

  .login-box {
    position: relative;
    padding-top: 16%;
    height: 100%;

    //font-size 16 prevents zoom on iphone which makes transitions look better.
    input {
      font-size: 16px;
    }
  }

  //// Modals
  .ToolTipPortal > [data-reactroot] {
    width: 60vw !important;
  }

  .mobile-x {
    display: block !important;
    position: absolute;
    top: 10px;
    right: 10px;
    margin-bottom: 5px;
    color: rgba(153, 153, 153, 0.65);
  }

  .multi-select-popover {
    left: 10vw !important;
    width: 80vw !important;
    line-height: normal !important;

    & span {
      float: none !important;
    }

    & button {
      margin-top: 25px;
    }
  }

  //Styling for mobile picture password login
  .picture-login-box {
    max-width: 90vw;
    width: 84vw;
    left: -6vw;
    padding: 1em;
  }

  //Stying for set picture password
  .set-picture-grid {
    box-sizing: border-box;
    max-width: 100vw;
    max-height: 90vh;
    overflow-y: scroll;
    width: 100vw !important;
    margin: 0vw !important;
    position: absolute;
    height: calc(100% - 120px);
    top: 60px;
    bottom: 60px;
    padding-bottom: 0px !important;

    .col-xs-4 {
      padding-bottom: 0vw !important;

      img {
        width: $picture-password-img-size;
        height: $picture-password-img-size;
      }
    }

  }

  #footer.student-footer {
    padding: 0px 14vw;
  }

  //Set the footer to fluidly let buttons fill
  #footer > * {
    justify-content: center !important;
      &.student-footer:first-child {
        flex: 1 1 25%;
        justify-content: flex-start !important;
      }
      &.student-footer.center-footer-btns {
        flex: 1 1 75%;
        justify-content: flex-end !important;
      }
  }

  //Reduce spread of footer buttons on mobile.
  .footer-btn-container {
    margin-right : 0px !important;
    flex: 1 1 auto !important;
  }

} //Close media query

#nav-button-alternative {
  z-index: 100;

  & > .open.dropup > ul {
    position: fixed;
    bottom: 113px;
    width: 100%;
    border-radius: 0px !important;
    border: none !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0) !important;
    padding: 0px;

    & > li {
      margin: -1px 0px -1px 0px;
    }

    & > li, & > li > a, & > li > a > button {
      position: relative;
      text-align: center;
      font-size: 24px;
      flex: 1;
      padding: 0px;

      & > a, & > a > button {
        border-radius: 0px !important;

        & > span {
          font-size: 13px;
          line-height: 35px;
        }
      }
    }
}
}

.resources-text-mobile{
  color: #4A4A4A;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 15px;
}

.resources-subtext-mobile{
  color: #9B9B9B;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 15px;
}

.full-width-btn {
  width: 100%;
}

#force-display-caret {
  display: inline-block !important;
}

.dropdown-toggle-styles {
  display: block !important;
  position: fixed !important;
  bottom: 70px;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 1;
  height: 46px;
  color: #F6F6F6 !important;
  border-radius: 0px !important;
  background-color: $swf-blue !important;

  &:active, &:hover {
    opacity: 1;
  }
}

.img-2-col {
  padding-right: 0px !important;
  padding-left: 0px !important;
  max-height: 43px;
  height: 43px;

  & > img {
    height: 100%;
    margin: auto;
  }

}

.remove-bs-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.navbar-collapse {
  border: none !important;
}

.data-8-col {
  padding-right: 0px;
  padding-left: 0px;
}

.add-15-padding-top-to-mobile {
  padding-top: 15px;
}

.datetime-close-x, .mobile-x {
  display: none;
}

.modal-text-styles {
  cursor: pointer;
  padding-bottom: 10px;
  color: #3498DB;
  font-family: ProximaNova;
  font-size: 15px;
  line-height: 19px;
}

.modal-text-header-styles {
  padding-bottom: 10px;
  color: #999999;
  font-family: ProximaNova;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 14px;
}

.mobile-datetime-wrapper {
  z-index: 9999999999;
  cursor: pointer;
  position: fixed;
  right: 450px;
  top: 70px;
}

.myclass-tooltip-menu {
  cursor : pointer;
  padding-left: 20px;

  &:first-of-type {
    padding-top: 20px;
  }
}

.picture-password-img {
  width: $picture-password-img-size-small;
  height: $picture-password-img-size-small;
  padding: 1em;
  cursor: pointer;
}

@media (max-width: $screen-xs-max){

  .myclass-tooltip-menu {
    padding: 0px;

    &:first-of-type {
      padding-top: 0px;
    }
  }

  //React datetime
  .rdtPicker {
    position: fixed;
    width: 100vw !important;
    left: 0vw !important;
    height: 55vh !important;
  }
  .datetime-close-x {
    display: block;
    position: fixed;
    z-index: 13;
    left: 94vw;
    top: 78px;
    color: rgba(153, 153, 153, 0.65);
    font-size: 18px;
  }

  .mobile-datetime {
    z-index: 12;

    > .rdtPicker {
      padding-top: 25px;
    }
  }
}
