#layoutHeader {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}

#layoutHeader #header-width {
  position: unset;
}

#layout {
  z-index: 0;

  #studentHome .rdtPicker {
    z-index: 0 !important;
  }
}

#layoutMain {
  padding-left: 0px;
  padding-right: 0px;
}

#layoutHero > * {
  padding-left: 0px;
  padding-right: 0px;
}

#layoutHero {
  padding-bottom: 0px;
  position:relative;
  top: -5px;
}

#layoutBody .for-scroll {
  position: unset;

}
#layoutBody .resource-content-margin {
  overflow: hidden;
}

#layoutBody {
  height: 100%;
}

.folder-details {
  #layoutBody {
    margin-top: 50px;
  }
}