.student-home-search {
  //font-family: "sw-font";
  display: inline-block;
  font-size: 32px;
  padding-top: 8px;
  padding-bottom: 15px;
  padding-right: 15px;
  color: #9B9B9B;
}

.schoolwide-left-header-icon {
   //font-family: "sw-font"
    font-family: "Open Sans", sans-serif;;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  height: 30px;
  padding-top: 21px;
  padding-bottom: 15px;
  color: #9B9B9B;
}

#studentEditSidebar {
  border-left: solid 1px darkgrey;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  background-color: #F5F5F5;
  padding-bottom: 75px;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  right: 0;
  bottom: 65px;
  top: 0;
}

.student-sidebar-user-icon {
  font-size: 6em;
  height: 2em;
  width: 1.8em;
  margin: auto;
  margin-top: .4em;
  margin-bottom: .4em;
  padding-top:.3em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  background-color: #9da2af;
  text-align: center;
  i {
    font-size: 1em;
  }
}


#studentHome {

  @media (min-width: 768px) {
    .rdt {
      margin-bottom: 1em;
      border-radius: 5px;
      border: 1.8px solid #F5F5F5;
      background-color: rgba(255, 255, 255, 0.85);
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
      margin-left: 1em;
      margin-right: 1em;
      min-height: 200px;

      &, .rdtPicker {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        .rdtPicker {
        width: 100%;
        height: 100%;
        padding: 0;
        margin-top: 0px;
        box-shadow: none;

          .rdtDays {
            height: 100%;
            table {
              height: 100%;
              td {
                height: auto;
              }
            }
          }
        }
      }
    }
  }

  #studentDropdownDatepicker {
    padding-bottom: 3em;
  }

  .teachers-list {
    margin-bottom: 1em;
    border-radius: 5px;
    border: 1.8px solid #F5F5F5;
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  }

  .my-teachers, .rdtSwitch {
    font-size: 15px;
    color: #009BBD;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    margin-top:10px;
  }

  .teachers-list {
    overflow-y: auto;
    height: 150px;
  }

  #student-view-empty-list {
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    margin-top:10px;
  }
}

.done, .assignment-item {
  height: 75px;
  border: 1px solid #CCD2DE;
  border-radius: 9px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.25);
  margin-bottom:10px;
}

.assignment-header, .done-header {
  height: 22px;
  border-radius: 9px 9px 0 0;
  background-color: #F5F5F5;
  margin-top:10px;
  margin-bottom:10px;
  padding-top:5px;
  text-align:center;
}
.hide-assignment-header{
  display:none;
}

.hide-done-header{
  display:none;
}
.item-type{
  height: 15px;
  width: 60px;
  border-radius: 20px 20px 20px 20px;
  margin-bottom: 3px;
  margin-left: 5px;
  position: absolute;
  bottom: 3px;
  text-align: center;
  color: white;
  font-size: 10px;
}
.item-type-resource{
  @extend .item-type;
  background-color:#46C5BC;
}

.item-type-book{
  @extend .item-type;
  background-color:#A276E6;
}

.item-type-assessment{
  @extend .item-type;
  background-color: #F49547;
}
.to-do{
  box-sizing: border-box;
  height: 30px;
  width: 100px;
  border: 1px solid #9B9B9B;
  border-radius: 4px 0 0 4px;
  background-color: #FFFFFF;
  color: #9B9B9B;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
}
.to-do-active{
  box-sizing: border-box;
  height: 30px;
  width: 100px;
  border: 1px solid #9B9B9B;
  border-radius: 4px 0 0 4px;
  background-color: #009BBD;
  color: #FFFFFF;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
}
.done-button {
  box-sizing: border-box;
  height: 30px;
  width: 100px;
  border: 1px solid #9B9B9B;
  border-radius: 0 4px 4px 0;
  background-color: #FFFFFF;
  color: #9B9B9B;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
}

.done-button-active{
  box-sizing: border-box;
  height: 30px;
  width: 100px;
  border: 1px solid #9B9B9B;
  border-radius: 0 4px 4px 0;
  background-color: #009BBD;
  color: #FFFFFF;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
}

.due-assignment-button {
  box-sizing: border-box;
  height: 30px;
  width: 160px;
  border: 1px solid #9B9B9B;
  border-radius: 0 4px 4px 0;
  background-color: #FFFFFF;
  color: #9B9B9B;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
}

.due-assignment-button-active{
  box-sizing: border-box;
  height: 30px;
  width: 160px;
  border: 1px solid #9B9B9B;
  border-radius: 0 4px 4px 0;
  background-color: #009BBD;
  color: #FFFFFF;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
}

.resource-assignment{
   //font-family: "sw-font"
    font-family: "Open Sans", sans-serif;;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 38px;
  color: #46C5BC;
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}

.assignment-chevron{
     //font-family: "sw-font"
    font-family: "Open Sans", sans-serif;;
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 9px;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    height: 16px;
    margin-top: 28px;
    color: #9B9B9B;
    margin-right:25px;
    float:right;
}

.assignment-date-format{
  color: #666666;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  text-align: center;
}

.assignment-title{
  color: #444444;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 18px;
  margin-top: 15px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assignment-subtext{
  color: #9B9B9B;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 15px;
}

.selected-teacher{
  cursor: pointer;
  font-size: 15px;
  align-self: center;
  float: left;
  margin-left: 30px;
  color: #F87622;
}

.each-teacher{
  color: #464B56;
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 22px;
  padding-left:5px;
}

.book-assignment{
   //font-family: "sw-font"
    font-family: "Open Sans", sans-serif;;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 9px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 37px;
  color: #A276E6;
  float: left;
  margin-top: 10px;
}

.assessment-assignment{
   //font-family: "sw-font"
    font-family: "Open Sans", sans-serif;;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 37px;
  color: #F49547;
  float: left;
  margin-top: 10px;
}
.inactive-teacher{
  cursor: pointer;
  font-size: 15px;
  align-self: center;
  float: left;
  margin-left: 30px;
  color: #CCCCCC;
}

.check-assignment{
  cursor: pointer;
  color: #EFEFEF;
  font-size: 22px;
  align-self: center;
  margin-top: 20px;
 text-align:center;

  // Don't display cursor in teacher view
  &.teacher-view {
    cursor: inherit;
  }
}

#remove-padding{
  padding-bottom: 0 !important;
}

.confirmation-dialogue-icon{
  cursor: pointer;
  color: #46C56D;
  font-size: 22px;
  align-self: center;
  margin-bottom: 20px;
  text-align: center;
}

.confirmation-dialogue{
  height: 145px;
  width: 250px;
  border: 1px solid #F5F5F5;
  border-radius: 9px;
  background-color: rgb(255,255,255);
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.5);
  position: absolute;
  margin-left:-175px;
  margin-top:28px;
  z-index:10;
  padding: 20px;
  font-family: "Open Sans", sans-serif;
  top: -76px;
  left: 0px;
}

#student-info-bar{
    height: 60px;
    background-color: white;
    border: 1px solid #CCD2DE;
    width: 100%;
    z-index: 999999 !important;
}

.confirm-cancel-button{
  width: 90px;
  border-radius:4px;
  padding:6px;
  font-weight:bold;
  font-size: 11px;
  line-height: 13px;
}

#threeStateBtn {
  width: 150px;
}
