.event-cell{
  border: 1px solid #CCD2DE;
  border-radius: 4px;
  display: flex;
  background-color: #FFFFFF;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.25);
  height: 85px;
  margin-right: 10px;
  width: inherit;
}

.event-title-agenda{
  color: #444444;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 18px;
  height: 1.2em;
  overflow: hidden;
  margin-bottom: 10px;
}

.event-desc-agenda{
  color: #9B9B9B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 32px;
}

.agenda-badge{
  height: 11px;
  width: 55px;
  border-radius: 100px;
  background-color: #9B9B9B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 8px;
  line-height: 9px;
  text-align: center;
  border-style: none;
  color: white;
  margin-left: 4px;
  display: inline-block;
}

.image-size-in-list-view {
  height: 55px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}


.chevron-list-view {
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 9px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  height: 16px;
  margin-top: 16px;
  float: right;
  color: #9B9B9B;
  margin-right:22px;
}
.folder-actions {
  display: inherit !important;
  padding-right: 0px !important;
  .inline-icons {
    display: inline-block;
    margin-left: 9px;
    &:last-child{
      margin-left:0px;
    }
  }

  .icon-icon-pen-circle-solid{
    margin-right: 5px;
    color: #9b9b9b;
    cursor: pointer;
  }
}
.editFolderContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // padding-bottom: 20px;
    .textBox-wrapper {
      padding-bottom: 0;
    }
    .icon-icon-check, .icon-icon-close-circle-solid{
      cursor: pointer;
      color: #9b9b9b;
    }
}