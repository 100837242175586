.left-component{
  background-color: white;
  width: 75%;
  float:left;
  border-right:1px solid #CCD2DE;
}

.right-component{
  width:25%;
  float: right;
  background-color: #F8F8F8;
}

.search-bar{
  background-color: white;
  height:60px;
  border-bottom:solid 1px darkgrey;
  //font-family:"ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 21px;
  padding-top: 15px;
  padding-bottom: 12px;
  position: fixed;
  z-index: 3;
  width: 100%;
  margin-left: 0 !important;
  top:0px;
}

.header-icon-right{
  display: inline-block;
  vertical-align: top;
}

.lesson-header-right{
  text-align: center;
}

@media screen and (max-width: 767px){
  .search-bar{
    height:auto;
  }
  .lesson-header-right{
    text-align: left;
  }
  .header-icon-right {
    margin-top: 5px;
  }
  .books-detail-zing-overlay-icon{
    display: none;
  }
  .detailed-description, .fill-height-or-more{
    //margin-top: 8vh;
    margin-top: 3vh;
  }
  .detailed-description .units-detailed-image-center{
    margin-top: 25px;
  }
  .btn.e-reader-btn{
    display: none;
  }
  .units-detailed-image-center .image-size {
    width: auto;
  }
  .menu > div{
    margin-top: 0 !important;
    top: 90px  !important;
  }
  .footer-btn-container{
    margin: 0;
    padding: 4px;
  }
  .footer-schoolwide-text {
    line-height: 10px;
  }
}

@media screen and (max-width: 991px){
  .footer-btn-container{
    width: auto;
  }
}

.rectangle-2 {
  box-sizing: border-box;
  height: 30px;
  width: 125px;
  border: 1px solid #3498DB;
  border-radius: 8px 0 0 8px;
  background-color: #009BBD;
}

.close-bar{
  height: 70px;
  background-color: white;
  position: fixed;
  bottom:0;
  width: 100%;
  border-top:solid 1px darkgrey;
  opacity: 0.9;
}

#my-panel{
  margin-left:51px;
  margin-right:51px;
  margin-bottom:15px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.25);
  border: 1px solid #CCD2DE;
  background-color: #FFFFFF;
}

#my-custom-panel{
  @extend #my-panel;
  cursor: pointer;
  border:3px dotted #CCD2DE;
}

.align-button-grp{
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.header-icon{
  height: 30px;
  width: 30px;
  margin:auto 6px 10px 8px;
}

.format-customize-button {
  background-color: #46C56D;
  color: white;
  padding: 14px 10px;
  margin-right: 15px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-size: 10px;
  line-height: 1px;
  float: right;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  width: 110px;
}

.format-text{
  float:right;
  padding-top: 23px;
  padding-right: 10px;
  letter-spacing: .1px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
}

.view-icon{
  height: 15px;
  width: 20px;
  float: right;
}

.view-icon-in-text{
  margin: 20px 0 10px 8px;
  float: right;
  font-size: 19px;
  color: #9B9B9B;
}

.arrow-icon{
    height: 13px;
    width: 13px;
    float: right;
    margin-top: 10px;
    cursor: pointer;
}

.image-size{
    display: block;
    width: 100%;
    height: auto;
    margin: auto;
}

.custom-unit-image-size{
    display: block;
    height: 150px;
    margin: auto;
}

.image-size-in-text-content{
  margin-top: 5px;
  margin-bottom: 5px;
  width:40px;
  height:53.5px;
  float: left;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
}

.icon-in-description{
  height: 10px;
  width: 15px;
  margin-top: 3px;
  margin-right: 2px;
  float: left;
}

.pull-right-like{
  @extend .font-properties-like;
  float: right;
  padding-right: 10px;
}

.pull-right-view{
  @extend .font-properties-like;
  float: right;
  padding-right: 10px;
}

.detailed-description{
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Open Sans", sans-serif;
}

.add-padding-to-each-item{
  padding-top: 15px;
}

.list-of-items{
  margin-left: 30px;
  margin-right: 30px;
  font-family: "Open Sans", sans-serif;
}

.print-only{
  background-color: #9B9B9B;
  color: white;
  max-width: 100px;
  line-height:8px;
  border-radius: 26.5px !important;
  font-size:7px;
  margin-left:4px;
  display: inline-block;
  border-style: none;
  font-weight: 700;
}

.rss.opacity {
  -webkit-filter: opacity(50%);
  //filter: opacity(30%);
}

.heading-format-in-panel{
  font-weight: 700;
}

#columns-padding-in-panel{
  padding-left: 30px;
}

.heading-format-in-detailed-description{
  color: darkgrey;
  font-weight: 700;
  font-size: 13px;
}

.author-text-format{
	color: #9B9B9B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
	font-size: 13px;
	line-height: 16px;
}

.add-border{
  border-top: 1px solid #CCD2DE;
  margin-left: 55px !important;
  margin-right: 1px !important;
}

#nav-buttons .btn{
  padding:3px 12px 6px 12px;
}

.format-hr{
  margin-top: 10px;
  margin-bottom: 10px;
}

.preparing-to-teach{
  background-color: #009BBD;
  color: white;
  padding: 14px 10px;
  margin-top: 15px;
  margin-left: 15px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-size: 10px;
  line-height: 1px;
  float: right;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  width: 100%;
}

.fill-height-or-more {
  min-height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-top: 60px;
}

//For left and right components (main content and detailed description)
.some-area {
  > div {
    padding: 1rem;
    &:nth-child(1) {
      background-color: white;
      flex-basis:auto;
      flex-grow: 1;
      flex-shrink:1;
      width:85%;
      @media screen and (max-width: 767px) {
        display:none;
      }
    }
    &:nth-child(2) {
      width: 360px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      background-color: #F8F8F8;
      border-left:1px solid #CCD2DE;
      margin-right: 0;
      transition: margin 0.3s ease;
    }
  }
}

.some-area-without-info{
  > div {
    padding: 1rem;
    &:nth-child(1) {
      background-color: white;
      flex-basis: auto;
      flex-grow:1;
      flex-shrink:1;
      width:85%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    &:nth-child(2) {
      border-left:1px solid #CCD2DE;
      width:360px;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-right: -100%;
      }
      margin-right: -360px;
      transition: margin 0.3s ease;
    }
  }
}

.some-area-initial {
  > div {
    padding: 1rem;
    &:nth-child(1) {
      background-color: white;
      width: 85%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    &:nth-child(2) {
      width:360px;
      @media screen and (max-width: 767px) {
        width: 100%;
        display: none;
      }
      background-color: #F8F8F8;
      border-left:1px solid #CCD2DE;
      margin-right: 0;
    }
  }
}

.browse-demo-icon
{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #46C56D;
  font-size: 30px;
  cursor: pointer;
}

.search-demo-icon
{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  font-size: 30px;
  padding-left: 5px;
  padding-right: 5px;
  color: #009BBD;
  cursor: pointer;
}

.info-demo-icon
{
  cursor: pointer;
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #009BBD;
  float: right;
  font-size: 30px;

}

.chevron-left-demo-icon{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  cursor: pointer;
  color:#9B9B9B;
}

.verticalLine {
  border-left: 1px solid  #D8D8D8;
  border-right: 1px solid  #D8D8D8;
  padding-left : 15px;
  padding-right : 15px;
  float: right;
}

.add-padding-to-icons{
  padding-left : 5px;
  padding-right : 5px;
  float: right;
}

.add-padding-back-icon{
  padding-left : 10px;
  padding-right :15px;

}

.unit-name{
  font-size: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #4A4A4A;
}

.font-properties-like{
  font-size: 13px;
  line-height: 16px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  color: #666666;
}

.heart-demo-icon{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
  opacity: 0.3;
}

.units-detailed-image-center {
  position: relative;
  overflowY: hidden;
  height: auto;
  border: 2px solid #F5F5F5;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1), 0 2px 6px 0 rgba(0,0,0,0.05);
}

.vocabulary-content{
  margin-top:30px;
  margin-bottom: 30px;
  // height:300px;
}

.word-style{
  color: #666666;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  height: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 21px !important;
}

.text-sub-heading {
	color: #4A4A4A;
	//font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
	font-size: 15px;
	font-weight: bold;
	margin-top: 20px;
}

.assessment-width{
  padding-top:15px;
  padding-bottom: 15px
}

.add-line{
  border-top: 1px solid #CCD2DE;
  margin-left: 55px !important;
  margin-right: 1px !important;
}

.assessment-image{
  max-height:80px;
  max-width: 80px;
	border: 1px solid #F5F5F5;
	border-radius: 4px;
	background-color: #FFFFFF;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1), 0 2px 6px 0 rgba(0,0,0,0.05);
}

@media screen and (max-width: 767px) {
  .assessment-image{
    max-height:60px !important;
    max-width: 60px !important;
  }
  .assessment-name-content{
    width: 60% !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .assessment-description{
   width: 80% !important;
  }
  .assessment-flex-style{
    width:100% !important;
  }
  .assessment-flex-heading{
    width:100% !important;
  }
  .assessment-sub-name {
    width:auto !important;
  }
  .assessment-name-content{
    width: auto !important;
  }
  .assessment-flex-sub-heading{
    display : none !important;
  }
  .unit-name{
    font-size: 17px !important;
    width: 53% !important;
    padding-left: 0px !important;
    float: left !important;
  }
  .hover-icon-unit{
    font-size: 25px !important;
    padding-right: 44px !important;
    margin-right: -2px;
  }
  .panel-click-area{
    flex-direction:column !important; 
  }
  .panel-item {
    padding: 5px !important;
    padding-left: 20px !important;
  }
  .panel-item-name{
    width: auto !important;
  }
  .panel-item-type{
    width:auto !important;
  }
  .panel-item-standard{
    width:auto !important;
  }
}

.view-icon-assessment{
  height: 15px;
  width: 20px;
}

#menu-item-drop-down{
  width:100% !important;
  border-radius: 0px !important;
}
.assessment-name-content{
	/*width: 234.5px;*/
  width:auto;
	color: #444444;
	//font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
	font-size: 15px;
	line-height: 18.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assessment-sub-name {
	color: #9B9B9B;
	//font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
	font-size: 13px;
	line-height: 16px;
	/*width: 294.5px;*/
  width:auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.assessment-description{
  border-width: 0.1px;
  border-left-color: rgba(1, 1, 1, 0);
  border-right-color: rgba(1, 1, 1, 0);
  border-top-color: rgba(1, 1, 1, 0);
  border-style: solid;
  flex: 1;
  display: flex;
  justify-content: center;
  margin-left: 15px;
  padding-bottom: 10px;
  padding-top:10px
}

.assessment-flex-style{
  display : flex;
  flex-direction : row;
  flex : 1
}

.assessment-flex-heading{
  display : flex;
  justify-content : center;
  flex-direction :column;
  flex : 1
}
.assessment-flex-sub-heading{
  display : flex;
  align-items: center;
}

.assessment-flex{
  flex-direction: row;
  display: flex;
}

.assessment-image-content{
  cursor: pointer;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  width: 80px;
  align-items: center;
  position: relative;
}


.content-heading{
	color: #444444;
	//font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
	font-size: 15px;
	line-height: 18.5px;
}

.pointer-hover{
  cursor: pointer
}

.cal-solid-demo-icon{
	@extend .browse-demo-icon;
	color: #FFFFFF;
	background-color: #46C56D;
	border-radius: 15px;
}

.collapsible-text-color{
  color:#9B9B9B
}

.collapsible-arrow-right{
    color: #4A4A4A;
    font-size: 11px;
    float: right;
    padding-top: 5px;
    cursor: pointer;
}

.collapsible-arrow-down{
    font-size: 6px;
    color: #4A4A4A;
    float: right;
    padding-top: 8px;
    padding-right: 8px;
    cursor: pointer;
}

.demo-icon-view{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 23px;
  float: right;
  color: #9B9B9B;
}

.demo-detail-icon-view{
   font-size: 23px;
   color: #9B9B9B;
}

.icon-flex-right{
  align-self: right !important;
}

.more-demo-icon-lesson{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  /*display: inline-block;*/
  display: none;
  width: 16px;
  text-align: center;
  font-variant: normal;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #9B9B9B;
  margin-right: 12px;
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.trash-icon-new-unit {
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  width: 16px;
  text-align: center;
  font-variant: normal;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #9B9B9B;
  margin-right: 12px;
}

.detail-link{
  color: #333333;
}

.selected-checkbox-icon-lesson {
  cursor: pointer;
  color: #46C56D;
  font-size: 27px;
  align-self: center;
  float: left;
  //margin-left: 30px;
}

.detail-link:hover{
  color: #333333;
}

.books-detail-zing-overlay-icon{
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 84px;
  margin: -41px 0px 0px -41px;
}

.books-detail-zing-overlay-icon-small{
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  margin: -15px 0px 0px -15px;
}

.text-in-collapsible-component{
  margin: 15px;
}

.hover-icon-unit{
  color:#46C56D;
  padding-right:55px;
  border-right:1px solid #999999;
  cursor: pointer;
  width: 1em;
  margin-right: .2em;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  font-size: 30px;
}

.popover-success-icon{
  cursor: pointer;
  color: #46C56D;
  font-size: 39px;
  align-self: center;
  float: left;
}

.unit-success-popover{
  background-color: white;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  display: flex;
  line-height: 50px;
  position: fixed;
  left: 68%;
  top: 12%;
  z-index: 10;
  width: 400px;
  border: 1px solid #F5F5F5;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
  padding: 20px 20px 20px 20px;
  margin-right: 20px;
}

@media (max-width: 576px){
.unit-success-popover{
  left:6% !important;
  width: auto !important;
}
.popover-success-text{
  line-height: 22px !important;
}
}
.popover-success-text{
  line-height: 30px;
  font-weight: normal;;
}

.panel-lesson-content{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.panel-click-area{
  display: flex;
  flex-direction: row;
  align-items: center;
  width:90%;
}

.panel-item-name{
  width: 50%;
}

.panel-new-item-name{
  width: 30%;
}

.panel-item-type{
  width: 50%;
}
.panel-item-standard{
  width: 50%;
}
.panel-item-rationale{
  width: 100%;
}

.panel-new-item-type{
  width:25%;
}
.panel-new-item-standard{
  width: 40%;
}

.panel-view-icon{
  //width: 5%;
}

.panel-item{
  padding: 20px;
  align-self: center;
  cursor: pointer;
}

.anchor-color, .anchor-color:hover, .anchor-color:visited{
  color:#3f3f3f;
}
