.global-search-component{
  border-left: solid 1px darkgrey;
  background-color: #F5F5F5;
  height:100%;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
}

.global-searchbar-textbox{
  padding: 15px;
  padding-bottom: 0 !important;
  background-color: white !important;
}

.planner-container {
  position: relative;
  bottom: 0px;
  margin-top: 30px;
  flex-grow: 1;

  .rbc-time-view {
  }

  .rbc-calendar {
    height: calc(96vh - 60px - 70px);
  }
}

.global-search-text-col {
  border-bottom: 1px solid #ccd2de;
  display: flex;
}

.global-search-result-img-col {
  padding-right: 0;
}

.global-search-image{
  height: 3em;
  margin-top: .6em;
  margin-bottom: .6em;
  border: 1px solid #F5F5F5;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1), 0 4px 12px 0 rgba(0,0,0,0.05);
  overflow: hidden
}

.global-lesson-search-results {
  height: 40em;
}

.global-search-row {
  border-bottom: 1px solid #CCD2DE;
  padding-bottom: .5em;
  padding-top:.5em;
  padding-right: .3em;
  display: flex;

  .media-left {
    padding-left: .5em;
    img {
      min-width: 3em;
      width: 50px;
    }
  }

  .media-body {
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: middle;
  }
}

.global-image-center-search{
  padding-bottom:.5em;
  height: 4em;
  margin:auto;
  max-width: 100%;
  min-width: 60%;
  display:block;
  cursor: pointer;
}

.global-search-result-text {
  float: left;
  cursor: pointer;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 14px;
  max-height: 42px;
  align-self: center;
  color: #4A4A4A;
}

.new-global-search-component{
  display: flex;
  flex: 1;
  flex-direction: column;
  border-left: solid 1px darkgrey;
  background-color: #F5F5F5;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
}

// Text above search input "Search & Filters"
.global-search-heading{
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
  justify-content: space-between;
  align-items: center !important;
  //font-family: 'ProximaNova', sans-serif;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
}


.global-clear-filter{
  display: flex !important;
  align-items: center !important;
  justify-content: space-between;
  padding: 2px 5px !important;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 12px !important;
  border: 1px solid #33afca;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 10px;

}

.global-clear-filter:hover{
  background-color: #f2fafb;
}


.global-clear-filter span{
  color: #a29e9e;
  margin-right: 5px !important;
}

.dropdown-labels-container{
  padding: 7px 0px;
  border-top: 1px solid #f2fafb;
}

.dropdown-labels-container:hover{
  background-color: #f2fafb
}

.dropdown-labels p{
  //font-family: 'ProximaNova', sans-serif;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #4f4f4f;
  transition: all;
  transition-duration: 200ms;
  margin: 0;
}

.dropdown-labels.control{
  margin: 0;
}

.dropdown-labels p:hover{
  color: #84b8d9;
}