.import-modal {
  width: 500px;
  /*left: calc(50% - 250px);
  position: absolute;*/

  position: fixed;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 50%;

  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  /*z-index: 7;*/
  z-index: 300;
  background-color: white;
  pointer-events: all;
}

.import-text {
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
}

.import-modal-button {
  height: 30px;
  width: 175px;
  border: 1px solid #9da2af;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  color: #FFFFFF;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  background-color:#009BBD;
}

.csv-download-button{
  @extend .import-modal-button;
  border-radius: 4px;
  line-height: 13px;
  color: #FFFFFF;
}

.csv-download-button:disabled{
  opacity: .8;
}

.import-modal-browse-button {
  @extend .import-modal-button;
  cursor: pointer;
  color: #FFFFFF;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  line-height: 28px;
}

.import-modal-browse-button:hover{
  @extend .import-modal-button;
  cursor: pointer;
  opacity: .8 !important;
}

.import-modal-upload-button {
  @extend .import-modal-button;
  border-radius: 4px;
  cursor: pointer;
  color: #FFFFFF;
  line-height: 28px;
  background-color: #46C56D;
}

.import-modal-upload-button:disabled {
  background-color: #9B9B9B !important;
  opacity: 1 !important;
}

.error-failure-messages{
  color: #d21528;
  ul{
    list-style: none;
    padding-left: 0;

    li {
      border: 1px solid;
      margin: 5px 0;
      padding: 5px;
    }
  }
  table, td, th{
    border: 1px solid;
  }

  table{
    border-collapse: collapse !important;
    border-radius: 0;
    width: 100%;
    th, td{
      padding: 7px 5px;
    }
  }
}