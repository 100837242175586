.menu {
  display:block;
  font-size: 15px;
  $menu-width:355px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;

  >div {
    position:fixed;
    z-index:199;
    top:0;
    width:$menu-width;
    @media screen and (max-width: 576px) {
        width: 100%;
    }
    box-sizing: border-box ;
    transition: -webkit-transform ease 250ms;
    transition: transform ease 250ms;
    border-left: solid 1px darkgrey;
    margin-top:60px;



    &.right {
      background:#F5F5F5;
      right:$menu-width*-1;
      @media screen and (max-width: 576px) {
        right: 100%*-1;
        }
    }

    &.visible.right {
      transform :(translate3d($menu-width*-1, 0, 0));
      @media screen and (max-width: 576px) {
        transform :(translate3d(100%*-1, 0, 0));
        }
    }


    &.visible.right::after {
      content: "";
      display: block;
      height: 150px;
    }

    >.menu-item {
      float:left;
      width:100%;
      margin:0;
      padding:10px 15px;
      border-bottom:solid 1px darkgrey;
      cursor:pointer;
      box-sizing: border-box ;
      color:black;
      font-size:10px;
      font-weight: 100;
      //font-family:"ProximaNova";
      font-family: "Open Sans", sans-serif;
      color: #4A4A4A;



      &:hover {
        color:#F0F0F0;
      }
    }
  }
}

.center-align-heading{
    text-align: center;
    padding: 20px;
    margin: auto;
    border-bottom: 1px solid #CCD2DE;
}

.filter-options-heading{
	color: #4A4A4A;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 16px;
	font-weight: bold;
	line-height: 19.5px;
    text-align: center;
    position: relative;
}

.create-folder-icon{
    text-transform: uppercase;
    font-weight: bold;
    /*font-size: 22px;*/
    font-size: 16px;
    margin-bottom: 30px;
    color: #4a4a4a;
    display: block;
    i{
        color: #009bbd;
    }
}

.global-searchbar-textbox{
    position: relative;
    img{
        position: absolute;
        bottom: 28px;
        left: 28px;
    }
}

.menu-label{
    padding: 15px;
    font-size: 13px;
    font-weight: bold;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    color: #4A4A4A;
}

.menu-item-dropdown{
    @extend .menu-label-withborder;
    font-weight: 100;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    color: #4A4A4A;
}

.menu-label-withborder {
    @extend .menu-label;
    border-bottom:solid 1px darkgrey;
}

.chevron-size{
    height: 12px;
    width: 30px;
    float: right;
}

.alert-filter {
    padding: 6px;
    background-color: #F87622;
    color: white;
    max-width: 250px;
    line-height:20px;
    border-radius: 26.5px !important;
    font-size:10px;
    margin-top:3px;
    margin-left:4px;
    display: inline-block;
}

.text-format{
    margin-left: 10px;
    margin-right: 10px;
    font-weight:600;
}

.closebtn {
    color: #F87622;
    font-weight: bold;
    float: left;
    font-size: 20px;
    line-height: 15px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 50%;
    background-color: white;
    width: 15px;
    text-align: center;
    margin-top: 2px;
    padding-bottom: -18px;
    padding-top: 1px;
}

.closebtn:hover {
    color: black;
}

.link-style{
    float: right;
    color: #3498DB;
    font-weight: 600;
}

.show-slideout{
  display: block;
}

.hide-slideout{
  display: none;
}

.close-filter{
    height: 15px;
    width:15px;
    float: right;
}

.label-heading{
	color: #4A4A4A;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 11px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 13.5px;
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 5px;
}

.normal-font {
	color: #4A4A4A;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 13px;
	line-height: 16px;
    font-weight: normal
}

.filter-border{
    border-bottom: 1px solid #CCD2DE;
    border-top: 1px solid #CCD2DE;
}

.filter-border-width{
    @extend .normal-font;
    padding:10px 0 10px 15px;
}

.sideout-dropdown{
    height: 15px;
    width: 15px;
    float: right;
    margin-top: 4px;
    cursor: pointer;
}

.dropdown-collapsible-item{
    padding:5px 0 5px 0;
}

.dropdown-collapsible-item-text{
	width: 223px;
	color: #464B56;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 13px;
	line-height: 15px;

}

.filter-seperator{
	border-bottom:1px solid #CCD2DE;
    padding: 0 8px;
}

.filter-options{
  height: fit-content !important;
  margin-right: 15px;
  margin-left:15px;
  margin-bottom: 20px;
}

.collapsible-dropdown{
  font-size: 14px;
}

.collapsible-dropdown:hover{
  background: #F2FAFB !important;
  transition: all;
  transition-duration: 150ms;
}

.control {
	position: relative;
	display: block;
	margin-bottom: 15px;
	padding-left: 25px;
	cursor: pointer;
	color: #464B56;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 14px;
	line-height: 15px;
    font-weight: 400;
}

.control input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.control:hover {
 opacity: 70 !important;
  transition: all;
  transition-duration: 150ms;
}

.control__indicator {
	  position: absolute;
    top: 0px;
    left: 0;
    width: 15px;
    border-radius: 5px;
    height: 15px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
}

.control--radio .control__indicator {
	border-radius: 50%;
}

/* Hover and focus states */
// .control:hover input ~ .control__indicator,
// .control input:focus ~ .control__indicator {
// 	background: #ccc;
// }

/* Checked state */
.control input:checked ~ .control__indicator {
  background: #33afca;
  border: none
}

/* Hover state whilst checked */
// .control:hover input:not([disabled]):checked ~ .control__indicator,
// .control input:checked:focus ~ .control__indicator {
// 	background: #0e647d;
// }

/* Disabled state */
// .control input:disabled ~ .control__indicator {
// 	pointer-events: none;
// 	opacity: .6;
// 	background: #e6e6e6;
// }

/* Check mark */
.control__indicator:after {
	position: absolute;
	display: none;
	content: '';
}

/* Show check mark */
.control input:checked ~ .control__indicator:after {
	display: block;
}

/* Checkbox tick */
.control--checkbox .control__indicator:after {
    top: 3px;
    left: 6px;
    width: 4px;
    height: 8px;
    transform: rotate(45deg);
    border: solid #fff;
    border-width: 0 2px 2px 0;
}

/* Disabled tick colour */
.control--checkbox input:disabled ~ .control__indicator:after {
	border-color: #7b7b7b;
}

.tag-name {
	  color: #FFFFFF;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    padding-left: 6px;
    padding-right: 3px;
}

.disabled-checkbox{
    background: #ccc;
    opacity: 0.5;
}
