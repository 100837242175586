@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.profile-header{
  height: 60px;
  width: 100%;
	box-sizing: border-box;
	border: 1px solid #CCD2DE;
	background-color: #FAFAFA;
  position: fixed;
  z-index: 5;

}

.profile-header-name{

	color: #4A4A4A;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 17px;
	font-weight: bold;
	line-height: 60px;
	text-align: center;
}

@media (max-width: 576px){
  .content-center-width{
    width:100% !important;
  }
  .xs-scroll-content{
    position:absolute;
    bottom:70px;
    top:150px;
    overflow:auto;
    left:0px;
    right:0px;
  }
}

.content-center-width{
    width:50%
  }

.profile-picture{
    border-radius: 50%;
    height: 125px;
    width: 125px;
    border: 1px solid #979797;
    font-size: 88px;
    margin: auto;
    color: #ffffff;
    background-color: #9da2af;

}

.edit-profile{
	color:red
}

.logout-button {
  height: 30px;
  width: 130px;
  border: 1px solid rgb(0, 155, 189);
  border-radius: 4px;
  background-color: rgb(0, 155, 189);
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
  color: #FFFFFF;
}
.password-validation-messages{
  //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
  padding-left: 3px;
  color: #9B9B9B;
}

.padding-under-fixed-header {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-top: 60px;
}

.set-password-under-btn-group {
  overflow-y: visible;
  margin-top: 0px;
  height: 100%;

  @media (max-width: $screen-xs-max){
    margin-top: 50px !important;

    > .set-picture-row {
      margin-bottom: 50px !important;
    }
  }
}

.row-padding-lr {
  padding-left: 15px;
  padding-right: 15px;
}
