@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.header-browse-icon-height{
  //padding-bottom: 2px;
  //display: inline-block;
  padding-top: 3px;
  float: left

}

.header-text{
  float: left;
}

#header-browse {
  font-size: 14px;
  color:#9B9B9B;
  // margin: auto 6px 5px auto;
}

#browse {
  color: #9B9B9B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  line-height: 26px;
  text-decoration: none
}

#header-width{
  margin-bottom: 0px;
  height: 60px;
}

.containerDropdown{
  display:flex;

  @media(max-width: $screen-xs-max){
    //Screen width - "Browse /" on the left - Magnifying glass icon - two-header-btns - chevron-down - padding/tweaking
    width: calc(100vw - 86px - 20px - 84px - 18px - 10px);
  }
}

.fixed{
  white-space: nowrap;
}

.fixed-item{
  flex-grow: 1;
}

.header-teaching-resource{
  color: #666666;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 26px;

  @media(max-width: $screen-xs-max){
    //Width of .containerDropdown - chevron-down
    max-width: calc(100% - 18px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#header-icon{
    color: #9B9B9B;
    font-size: 30px;
    float: right;
    padding-top: 8px;
    cursor: pointer;
}

.header-chevron{
    font-size: 9px;
    padding-left: 5px;
    color:#009CBD;
    padding-top:5px;
}

.header-chevron-padded{
    margin-left:10px;
    height: 10px;
    width: 18px;
}

#header-right-icons{
  padding-left:20px;
}

#browse-nav-custom > li > a {
    padding-left: 0px !important;
    padding-right: 8px !important;
}

.filter-number-background{
	  height: 18px;
    width: 20px;
    border-radius: 4px;
    background-color: #33afca;
    float: right;
    //margin-top: 3px;
    text-align: center;
    margin-right: 10px;
}

.filter-number{
	  color: #FFFFFF;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 13px;
    padding-top: 2px;
    padding-left: 1px;
}

.clear-filter{
	  color: #3498DB;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 13px;
    float: right;
    padding-top: 4px;
    cursor: pointer;
}

 .header-popup-option{
    cursor: pointer;
    padding-bottom: 10px;
    color: rgb(52, 152, 219);
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    line-height: 19px;
 }



// .tooltip {
//     position: relative;
//     display: inline-block;
// }

// .tooltip .tooltiptext {
//     visibility: hidden;
//     width: 120px;
//     background-color: green;
//     color: #fff;
//     text-align: center;
//     border-radius: 6px;
//     padding: 5px 0;
//     position: relative;
//     z-index: 1;
//     bottom: 125%;
//     left: 50%;
//     margin-left: -60px;
//     transition: opacity 1s;
// }

// .tooltip .tooltiptext::after {
//     content: "";
//     position: relative;
//     top: 100%;
//     left: 50%;
//     margin-left: -5px;
//     border-width: 5px;
//     border-style: solid;
//     border-color: green transparent transparent transparent;
// }

// .tooltip:hover .tooltiptext {
//     visibility: visible;
//     opacity: 1;
// }

.header-container{

}
.header-top-part{
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #33afca;
  height: 72px;
}
.header-bottom-part{
  margin-bottom: 20px;
}
.header-main{
  flex: 4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.main-logo{
  width: 100px
}
.main-logo:hover{
  cursor: pointer
}
.header-nav-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.header-nav-ul li {
  float: left;
  display: block;
  color: #222222;
  text-align: left;
  padding: 20px;
  text-decoration: none;
}

.header-nav-li.active{
  color:#33afca;
  text-decoration: underline;
}

.header-nav-ul li:hover {
  text-decoration: underline;
  cursor: pointer;
}
.header-spacing{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
}
.logout-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70px;
}
.logout-btn:hover{
  cursor: pointer;
}

body{
  font-family: "Open Sans", sans-serif;
}