.admin-hero {
  width: 100%;
  background: linear-gradient(#3c8cb4, #009cbd);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.details-info{
  padding: 15px 30px;
  width: 100%;
  margin-top: 60px;
}

.school-data {
  width: 100%;
  display: inline-block;
}

.edit-data {
  float: left;
}

.edit-row {
  float: left;
}

.edit-school-district {
   color: #ffffff;
   display: inline-block;
   padding-right: 5px;
   font-size: 36px;
   top: -4px;
 }

.edit-school-district-close {
  color: #ffffff;
  display: inline-block;
  font-size: 30px;
  top: -4px;
  padding-right: 14px;
}

.district-name{
  color: #FFFFFF;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 52px;
  font-weight: 800;
  line-height: 55px;
}


.input-info-district{
  border: none;
  line-height: 20px;
  font-size: 20px;
  padding: 5px;
  font-weight: 400;
  border-radius: 6px;
}
.address-of-district{
  @extend .district-name;
  font-weight: 400;
  font-size: 35px;
  line-height: 30px;
}

.info-of-district{
  @extend .district-name;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  padding-top: 4px;
}
.disabled {
  color: grey;
  cursor: not-allowed;
  pointer-events: none;
  display: none;
}
.input-info-items{
  padding-bottom: 10px;
}

.edit-icon{
  display: inline-block;
  padding-left: 100px;
  font-size: 25px;
}

.dropdown-state-country{
  height: 33px;
  border: none;
  border-radius: 5px;
  width: 254px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  color: #9B9B9B;
  font-weight: 400;
}

.save-edit-district{
  font-size: 20px;
  background-color: #46C56D;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  line-height: 1px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  width: 110px;
  margin-top: 5px;
}

.edit-district-type {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 700;
}

.edit-district-item {
  width: 220px;
  color: #000;
}

.edit-district-address {
  width: 460px;
  color: #000;
}

.filter-panel{
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  margin-top: 10px;
}

.list-header{
  border-radius: 10px 10px 0px 0px;
  background-color: #F5F5F5;
  margin: 15px 15px 0px 15px;
  padding : 10px 10px 10px 10px;
  border: 1px solid #e7e7e7;
  border-bottom: 0;
  margin-top: 70px;
}
.add-item-icon{
  float:right;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  font-size: 23px;
  color:#46C56D;
}

.list-view-sys-admins{
  margin: 0px 15px 15px 15px;
  padding : 10px 10px 10px 10px;
  height: 400px;
  overflow-y: scroll;
  border: 1px solid #e7e7e7;
  border-radius: 0px 0px 10px 10px;
}
.list-view{
  margin: 0px 15px 15px 15px;
  padding : 10px 10px 10px 10px;
  border: 1px solid #e7e7e7;
  border-radius: 0px 0px 10px 10px;
}
.list-item{
  padding: 5px 5px 5px 5px;
}

.list-items{
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  color: #444444;
  font-size: 15px;
  line-height: 19px;
}

.list-item-image{
   height: 40px;
   width: 40px;
   box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
   border-radius: 50%;
   float:left;
 }

.list-item-user-icon{
  height: 40px;
  width: 40px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.20);
  border-radius: 50%;
  float:left;
  background-color: #9da2af;
  text-align: center;
  padding-top: 8px;
}

.list-item-user-img{
  font-size: 24px;
  color: #ffffff;
}

.reset-password-icon {
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  color: white;
  float: right;
  margin-top: 11px;
  margin-right: 10px;
  border: 1px solid #009BBD;
  border-radius: 50%;
  background-color: #009BBD;
  margin-left: 3px;
}

.delete-item{
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  color: white;
  float: right;
  margin-top: 11px;
  margin-right: 10px;
  border: 1px solid #D0021B;
  border-radius: 50%;
 background-color: #D0021B;
  margin-left: 3px;

}
.edit-item-button{
  color: white;
  background-color: #009BBD;
  border: none;
  float: right;
  margin-top:9px;
}

.item-border{
  border-bottom: 1px solid #e7e7e7;
  padding-top:50px;
  margin-left: 50px;
}

.edit-item-icon{
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  font-size: 23px;
  color:#46C56D;
  float: right;
  margin-top: 10px;
  margin-left: 10px;
}

.title-of-list{
//font-family : "ProximaNova"
  font-family: "Open Sans", sans-serif;
}

.item-sub-text{
  color: #9B9B9B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height:16px
}

.admin-bar{
  background-color: #f8f8f8;
  height:60px;
  border-bottom:solid 1px #e7e7e7;
  //font-family:"ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 21px;
  padding-top: 15px;
  padding-bottom: 12px;
  position: fixed;
  z-index: 5;
  top: 0px;
  width: 100%;
  margin-left: 0 !important;
}

.main-area {
  overflow-y: auto;
  height: 100vh;
}

.main-area .list-view:nth-of-type(2) {
  margin-bottom: 90px;
 }

.admin-search-box {
  border-radius: 5px;
  border:solid 1px #e7e7e7;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-right: 5px;
}

.can-hover:hover {
  cursor: pointer;
}

.add-user-form{
  display: block;
  width: 100%;
  padding: 7px 15px;
  //padding: 6px 6px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.add-user-form-error {
  border-color: #c44545;
}

.add-user-error {
  padding-top: 2px;
  color: #c44545;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  
}

// .add-user-error.fixed {
//   position: absolute;
//   transform: translate(-50%,-50%);
//   top: 65%;
//   left: 50%;
//   z-index: 1000;
//   background: #fff;
//   padding: 10px;
// }

.menu > div.right{
  transition: 0.3s;
}

.menu > div.visible.right {
  transform: none;
  right: 0;
}
.searchbox{
  position: absolute;
  right: 50px;
  top: 0;
  z-index:1;
}

.searchbox input{
  width: 100%;
}

.local-content-searchbar{
  margin: 10px 0;
  height: 30px;
  width: 60%;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding-left: 15px;
}

.add-user-success {
  padding-top: 2px;
  color: green;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
}

.delete-folder-success{
  position: absolute;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 30%;
  font-weight: bold;
}

.single-select-popover.folder-success-popver{
  transform: translate(-50%,-50%);
  left: 50%;
  top: 50%;
  font-size: 17px;
  height: auto;
  .selected-checkbox-icon-add-multiple{
    margin-left: 0;
  }
}


.add-user-error-alert.alert {
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0px;
}

.no-resource{
  padding: 20px 35px;
  display: block;
}

body{
  position: relative;
  min-height: calc(100vh - 0px);
  font-family: "Open Sans", sans-serif;
}

.dialog-modal {
  /*left:25%;
  position:absolute;*/

  position: fixed;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 50%;

  width:50%;
  box-shadow: 0 4px 24px 0 rgba(0,0,0,0.5);
  border-radius: 10px;
  /*z-index: 7;*/
  z-index: 300;
  background-color: white;
  pointer-events: all;
}

.dialog-modal-district {
  width: 70%;
  max-height: 100vh;
  overflow-y: scroll;
}

.dialog-modal-create-folder{
  max-width: 500px;
  text-align: center;
}
.dialog-header {
  box-sizing: border-box;
  height: 60px;
  width: 100%;
  border: 1px solid #e7e7e7;
  background-color: #f8f8f8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* position: fixed; */
  z-index: 2;
}

.dialog-footer {
  box-sizing: border-box;
  height: 60px;
  width: 100%;
  border-top: 1px solid #e7e7e7;
  background-color: #f8f8f8;
  /* bottom: 0; */
  /* position: fixed; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

 @media screen and (max-width: 576px) {
   .dialog-delete-button {
      width:110px !important;
   }
   .dialog-cancel-button{
      width:110px !important;
   }
   .dialog-modal{
     width:80% !important;
     left:10% !important;
   }
 }


.dialog-delete-button {
  height: 30px;
  width: 130px;
  border: 1px solid #D0021B;
  border-radius: 4px;
  background-color: #D0021B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
  color: #FFFFFF;
}

.dialog-save-button {
  height: 30px;
  width: 130px;
  border: 1px solid #46C56D;
  border-radius: 4px;
  background-color: #46C56D;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
  color: #FFFFFF;
}

.license-edit-btn {
  .dialog-save-button {
    height: 20px;
    width: 70px;
    font-size: 9px;
    margin: 0 0 5px 10px;
  }
}

.input-search-icon{
  position: absolute;
  bottom: 28px;
  left: 28px;
}

.license-edit{
  .mobile-datetime{
    display: inline-block;
  }
  .add-user-form{
    height: 24px;
    text-align: center;
    font-size: 12px;
    display: inline-block;
    margin: 2px 0 5px; 
  }
  .rdt.user-modal-form {
    display: inline-block;
    width: 75px;
    .form-control {
      height: 24px;
      font-size: 12px;
      padding: 0;
      text-align: center;
    }
  }
  h4{
    font-size: 12px;
    margin: 0;
    text-align: center;
  }
  .blocks{
    display: inline-block;
    margin-left: 20px;
    text-align: center;
    width: 70px;
  }
}

.dialog-cancel-button {
  height: 30px;
  width: 130px;
  border: 1px solid #9B9B9B;
  border-radius: 4px;
  color: #9B9B9B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  line-height: 13px;
  background-color: #FAFAFA;
  text-align: center;
}

.dialog-cancel-button.btn-cancel-blue{
  background: #009BBD;
  border: 1px solid #009BBD;
  color: #fff;
}

.dialog-spacer{
  padding: 25px;
}

.error-border{
  border: 1px solid #d01e1f;
}

.district-country-dropdown{
  height: 33px;
  border-radius: 5px;
  width: 254px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  color: #9B9B9B;
  font-weight: 400;
}

.form-wrapper-outer-district{
  /*padding-top: 20px;
  padding-bottom: 40px;*/
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #CCD2DE;
  border-bottom-color: white;
  border-top-color: white;
}

.gif-wrapper-outer{
    text-align: center;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 50px;

}

.status-message{
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
	font-size: 17px;
  float: left;
  padding-top: 25px;
  padding-bottom: 25px;
  max-width: 350px;
}

.status-message h1{
  font-size: 17px !important;
  font-weight: normal !important;
}

.status-message p{
  margin: 0px;
}

.inline-input{
  display: inline-block;
}

.gif-wrapper-inner{
    display: inline-block;
}

.inline-csv-button{
    display: inline-block;
}

.custom-file-input{
    margin-right: 10px;
    width: 175px;
    height: 30px;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    border: 1px solid #ccc;
    font-size: 11px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    font-weight: bold;
    padding-left: 10px;
}


input[type="file"] {
    display: none !important;
}

.close-modal{
  cursor:pointer;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  color: white;
  float: right;
  margin-right: .2em;
  margin-left: .2em;
  border: 1px solid #9da2af;
  border-radius: 50%;
  background-color: #9da2af;
  margin-left: 3px;
}

.bulk-add-suggestions{
  display: table;
  margin: 0 auto;
}

.bulk-add-suggestions h1{
    display: inline-block;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: bold;
}

.suggestions-wrapper{
    margin: 0 auto;
    padding-bottom: 25px;
}

.bulk-add-heading{
  color: #4A4A4A;
	//font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
	font-size: 17px;
	font-weight: bold;
	line-height: 0px;
	text-align: center;
	padding-top: 20px;
}

.bulk-add-header {
  box-sizing: border-box;
  height: 60px;
  width: 100%;
  border: 1px solid #e7e7e7;
  background-color: #f8f8f8;
  padding: 10px 10px 10px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 2;
}

.dialog-background{
  pointer-events: none
}

.background-disable{
  height: 100%;
  width: 100%;
  top:0;
  left:0;
  background-color:#000;
  position: fixed;
  z-index: 6;
  opacity: 0.5;
}

.non-breaking-span{
  display: block;
  float: left;
}

.required-asterisk {
  color: red;
}

.text-limitation{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-tab{
  font-weight: bold;
}
.header-tab .successful,.notsuccessful{
  text-align: center;
  font-size: 11px;
}
.alert-hidden{
  display: none !important;
}

.alert-shown{
  color: green;
  display: block !important;
}

.alert-error{
  color: red;
  display: block !important;
  padding: 0;
}