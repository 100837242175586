.my-class-group {
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  color: #9B9B9B;
  float: left;
  margin-top: 24px;
}
.my-class-more{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:30px;
  color: #9B9B9B;
  float: right;
  margin-top: 15px;
}
.my-class-add-user{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:30px;
 color: #9B9B9B;
  float: right;
  margin-top: 15px;
  cursor: pointer;
}
.my-class-search{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:30px;
  color: #9B9B9B;
  float: right;
  margin-top: 15px;
}
.my-class{
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  color: #666666;
  margin-top: 16px;
  margin-left: 23px;
  font-weight: 600;
}
.my-class-header {
  height: 40px;
  border-radius: 10px 10px 0 0;
  background-color: #F5F5F5;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
}
.format-radio-button {
  margin: 10px 0 0 10px;
  display: inline-block;
}
.my-class-arrow-down {
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 6px;
  color: #666666;
}
.my-class-arrow-down-div {
  display: inline-block;
  position: absolute;
  top: 10px;

  //This expands the size of the clickable div and can be limited to touch devices.
  &:after {
    content: '';
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: 0px;
    right: -22px;
  }
}
.my-class-more-icon {
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  width: 16px;
  text-align: center;
  font-variant: normal;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  height: 4px;
  margin-top: 16px;
  float: right;
  color: #9B9B9B;
  margin-right: 12px;
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);

  &:after {
    content: '';
    position: absolute;
    top: -10px;
    bottom: -26px;
    left: -10px;
    right: -16px;
  }
}

.classroom-text{
  color: #4A4A4A;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 17px;
  display: inline-block;
  margin-top: 10px;
}
.my-class-item-border{
    border-bottom: 1px solid #e7e7e7;
    padding-top: 50px;
    margin-left: 67px;
    margin-right: 20px;
}

.format-radio-button-student {
  margin: 10px 0 0 30px;
  display: inline-block;
}

.my-class-list-item{
  padding : 20px 5px 5px 5px;
  cursor: pointer;
}

.my-class-arrow-right {
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 15px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
  color: #666666;
}
.user-icon-add-student {
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 15px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  /* margin-left: 13px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  color: #009BBD;
  margin-top: 10px;
}

.group-icon-add-group {
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 15px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  /* margin-left: 13px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  color: #009BBD;
  margin-top: 4px;
}
.popover-text{
  color: #3498DB;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  line-height: 20px;
}

.popover-sub-text{
  color: #666666;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  line-height: 13px;
}


.add-group-header{
  height: 60px;
  border-bottom: 1px solid rgb(204, 210, 222);
  background-color: #F5F5F5;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
 text-align:center;

}
.add-group-footer{
  height: 72px;
  border-top: 1px solid rgb(204, 210, 222);
  background-color: #F5F5F5;
  position:fixed;
  bottom: 0;
  width:100%;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  z-index: 300;
}

.add-group{
  font-weight: 600;
  display: inline-block;
  margin-top: 20px;
  font-size: 18px;
}

.cancel-add-group{
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  color: #9B9B9B;
  background-color: #F5F5F5;
  font-weight: bold;
  text-align:center;
  line-height: 13px;
  border: 1px solid #9B9B9B;
  border-radius: 4px;
  margin-top: 21px;
  margin-bottom: 21px;
  margin-left: 15px;
  width: 130px;
  height: 30px;

}

.add-add-group{
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  margin-top: 21px;
  margin-bottom: 21px;
  float:right;
  margin-right: 15px;
  background-color: #46C56D;
  color:white;
  border: none;
  font-size: 11px;
  border-radius: 4px;
  font-weight: bold;
  line-height:13px;
  text-align:  center;
  width: 130px;
  height: 30px;
}
.add-group-content{
  position: absolute;
  top: 60px;
  bottom: 60px;
  overflow:auto;
  width: 100%;
}

.inner-add-group-content{
  //font-family:"ProximaNova";
  font-family: "Open Sans", sans-serif;
  margin-top: 20px;
  color: #4A4A4A;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 13px;
}

.button-students{
  //font-family:"ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: white;
  background-color: #009BBD;
  border-radius: 5px 5px 5px 5px;
  border:1px solid rgb(204, 210, 222);
  border-right:none;
  padding: 8px 25px 8px 25px;
  line-height: 13px;
  text-align:center;
  width:116px;
}
.button-additional{
  //font-family:"ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  border-radius: 5px 5px 5px 5px;
  border:1px solid rgb(204, 210, 222);
  border-right:none;
  padding: 8px 25px 8px 25px;
  line-height: 13px;
  text-align:center;
  width:auto;
}
.button-groups{
  font-size: 11px;
  //font-family:"ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #9B9B9B;
  background-color: white;
  border-radius: 0 5px 5px 0;
  border: 1px solid #9B9B9B;
  padding: 8px 25px 8px 25px;
  line-height: 13px;
  text-align:center;
  width:116px;
}

.my-class-list-item-user-icon {
  height: 40px;
  width: 40px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  float: left;
  background-color: #9da2af;
  text-align: center;
  padding-top: 4px;
  margin-left: 10px;
}
.my-class-add-group-user-icon{
  height: 40px;
  width: 40px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  float: left;
  background-color: #9da2af;
  text-align: center;
  padding-top: 6px;
  //margin-left: 10px;
  margin-top: 10px;
  font-size: 24px;
  color: #ffffff;
}
.add-student-icon{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 15px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  /* margin-left: 13px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 25px;
  color: #9B9B9B;
  margin-top: 5px;
  float: right;
  margin-right: 25px;
  cursor:pointer;
}
.add-student-icon-selected{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 15px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  /* margin-left: 13px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 25px;
  color: #46C56D;
  margin-top: 5px;
  float: right;
  margin-right: 25px;
}
.my-class-add-group-border{
  border-bottom: 1px solid #e7e7e7;
  margin-left: 64px;
  margin-right: 4px;
  margin-bottom: 6px;
}
.group-name{
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 6px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  /* -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); */
  /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); */
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  margin-bottom: 20px;
  color: #9B9B9B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  border: 1px solid #CCCCCC;
}
.my-class-add-user-selected{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:30px;
  color: #3498DB;
  float: right;
  margin-top: 15px;
}

.student-name{
  color: #444444;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 18px;
  margin-top: 5px;
  font-weight:500;
}

.student-sub-text{
  color: #9B9B9B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 16px;
  font-weight:500;
}

.search-by-student {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 6px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  /* -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); */
  /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); */
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  margin-bottom: 20px;
  color: #9B9B9B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  border: 1px solid #CCCCCC;
  padding-left:25px;
}

.add-group-error {
  padding-top: 8px;
  color: #c44545;
  padding-bottom: 8px;
  font-size: 12px;
}

.search-student {
  position: absolute;
  padding-top: 11px;
  padding-left: 7px;
  font-size: 11px;
  color: #9B9B9B;
}

.add-student-solid-icon {
  float: right;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  font-size: 23px;
  color: #46C56D;
  margin-right: 5px;
  margin-top: 8px;
  cursor:pointer;
}

.remove-student-solid-icon{
  @extend .add-student-solid-icon;
  color: #D0021B
}


.delete-student {
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
  color: white;
  float: right;
  margin-top: 11px;
  margin-right: 10px;
  border: 1px solid #D0021B;
  border-radius: 50%;
  background-color: #D0021B;
  padding: 3px;
}

.delete-units {
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: white;
  float: right;
  margin-top: 5px;
  margin-right: 10px;
  border: 1px solid #D0021B;
  border-radius: 50%;
  background-color: #D0021B;
  padding: 0px 8px 2px 8px;
  cursor: pointer;
}

.no-groups{
  position: absolute;
  top: 45%;
  left: 40%;
  font-size:15px;
  font-weight:bold;
  //font-family:"ProximaNova";
  font-family: "Open Sans", sans-serif;
  color: #9b9b9b;
}
.assign-calendar{
  position: absolute;
  padding-top: 11px;
  padding-left: 7px;
  font-size: 11px;
  color: #9B9B9B;
}

.panel-learning{
  height: 45px;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  margin-top:12px;
}

.elearning-icon{
  font-size: 20px;
  color:#009BBD;
  position: absolute;
  padding: 12px;
}

.text-elearning{
  position: absolute;
  color:#444444;
  padding: 14px;
  margin-left: 36px;
}

.chevron-right-lesson{
  float: right;
  padding: 12px;
  font-size: 15px;
  color:#444444;
  margin-top: 3px;
}

.skill-item-option{
  margin:10px;
}

.checkbox-label-skill{
  display: inline !important;
}
