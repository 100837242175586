#fixed-footer {
    background-color: #2C1A3C;
    width: 0;
}

.user-circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

#footer {
    display: flex;
    background-color: #2C1A3C;
    position:fixed;
    //position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 200;
}

//The only direct children of the footer should be the btn containers
#footer > * {
  display: flex;

  &.left-footer-btns {
    flex: 1 1 20%;
    justify-content: flex-start;
  }
  &.center-footer-btns {
    flex: 1 1 60%;
    justify-content: center;
  }
  &.right-footer-btns {
    flex: 1 1 20%;
    justify-content: flex-end;
  }
}

.footer-btn-container {
      cursor: pointer;
      //margin-top: auto;
      //margin-bottom : auto;
      margin: auto 10px;
      padding: 10px;
      width : 100%;
      max-width: 90px;
      //margin-right : 10px;
      justify-content : center;
      text-align:center;
      background-color: #2C1A3C;
      border-top: 5px solid #2c1A3C;
      border-left: 0px;
      border-right: 0px;

      &.active-footer-btn {
        background-color: rgba(255,255,255,0.2);
        border-top: 5px solid #529ed0;
        border-left: 1px solid black;
        border-right: 1px solid black;
      }

      &.disabled-footer-btn {
        background-color: rgba(255,255,255,0.6);
        border-top: 5px solid rgba(255,255,255,0);
        pointer-events: none;
      }
}

.footer-schoolwide-text {
    height: 12px;
    color: #FFFFFF;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 10px;
    text-align: center;
    width: 101%;
}

#footer > * {
  &.left-footer-btns {
    .footer-schoolwide-text {
      font-size: 8px;
    }
  }
  &.right-footer-btns {
    .footer-schoolwide-text {
      font-size: 8px;
    }
  }
}

.footer-menu {
    text-align: center;
}

.footer-menu-icons:hover {
    border-top: 5px solid #529ed0;
    border-left: 1px solid;
    border-right: 1px solid;
    text-align: center;
    opacity: 1.5;
}

.footer-menu-icons {
    text-align: center;
    float: left;
}

.footer-icon {
    font-size: 20px;
    padding-bottom: 7px;
    /*margin-left: 15px;*/
}
.footer-schoolwide-icon{
    font-size: 20px;
    color: #FFFFFF;
    padding-bottom: 7px;
    padding-right: 12px;
}

.footer-schoolwide-publishUpArrowIcon{
  width:30px;
  height:30px;
  margin-bottom:8px;
}

.notification-menu {
    margin-bottom: 0;
    font-size: 30px;
    padding: 5px;
}

.badge1 {
    position: relative;
}

.badge1[data-badge]:after {
    content: attr(data-badge);
    position: absolute;
    top: 14px;
    right: 5px;
    font-size: .7em;
    background: #FE3824;
    color: white;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 50%;
    box-shadow: 0 0 1px #333;
}

.student-icon{
    font-size: 20px;
    color: #FFFFFF;
    padding-bottom: 7px;
}

.schoolwide-icon-margin{
    font-size: 20px;
    padding-bottom: 7px;
    margin-left: -12px;
}

#lite-user-popup {
  margin-left: 125px;
}

#lite-user-planner-popup {
  margin-left: 15px;
}

#lite-user-popup a,
#lite-user-planner-popup a{
  color: white;
  text-decoration: underline;

  &:hover {
    text-decoration: underline !important;
  }
}

#lite-user-popup .tooltip-inner,
#lite-user-planner-popup .tooltip-inner {
  border-radius: 0;
  background-color: #3F99B9;
  color: white;
  max-width: 315px;
  padding: 10px;
}

#lite-user-popup .tooltip-arrow {
    color: white;
    border-top-color: #3F99B9;
    margin-left: -130px;
}

#lite-user-planner-popup .tooltip-arrow {
  margin-left: -20px;
  border-top-color: #3F99B9;
}

.custom-ui{
  text-align: center;
  width: 500px;
  padding: 40px;
  /*background: #28bae6;*/
  background:#2d5e8c;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
  h1{
    font-size:16px;
    text-align:justify;
    font-weight:bold;
  }
  ul{
    li{
      text-align:left;
    }
  }
  p{
    /*text-align:justify;*/
    font-size:15px;
    font-weight:bold;
  }
  button{
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;
  } 
}

@media screen and (max-width: 1300px) {
  .sidebar-filter-menu{
    padding-bottom: 25%;
  }
}