.my-library-cal{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #9B9B9B;
  font-size: 20px;
  margin-top:20px;
  float:right;
}

.my-library-add-user{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #9B9B9B;
  //font-size: 20px;
  font-size: 18px;
  float: right;
  cursor: pointer;
}

.remove-library-item{
    //margin-right: .1em;
    margin: 0 2px;
    line-height: 1em;
    color: #9B9B9B;
    //font-size: 20px;
    font-size: 16px;
    float: right;
    //margin-left: 10px;
    cursor: pointer;
}

img.add-folder-icon{
  vertical-align: top;
  display: block;
  position: relative;
  top: -2px;
  cursor: pointer;
}

.global-folder-header{
  font-weight: bold;
  text-align: center;
  //font-size: 25px;
  font-size: 16px;
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #CCD2DE;
  color: rgb(74, 74, 74);
  //font-family: 'ProximaNova';
  font-family: "Open Sans", sans-serif;
}

.folder-result-display .global-search-row{
  padding: 10px;
}

.folder-result-display .media{
  width: 100%;
}

.folder-result-display .global-search-result-text, .folder-result-display .icon-icon-trash{
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.folder-result-display .icon-icon-trash{
  color: #9b9b9b;
  cursor: pointer;
}

.back-btn{
  float: left;
  vertical-align: top;
  .cancel-add-group{
    margin-top: 13px;
    margin-bottom: 0;
  }
}

.my-folder-icon{
  filter: grayscale(100%);
  width: 100%;
  opacity: 0.75;
}
.add-folder-block .col-md-2{
  padding-bottom: 0 !important;
}

.add-folder-block .col-md-1{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.header-right-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  border: 2px solid #009BBD;
  border-radius: 20px;
  margin-top: 14px;
  text-align: center;
  background: #fff;
  cursor: pointer;
}
.header-right-icon.active {
  background: #009BBD;
}
.header-right-icon img {
  width: 90%;
  padding: 2px 0 0 0;
}
.header-right-icon.active img {
  filter: grayscale(1) brightness(0) invert(1);
}
.add-folder-block .folder-name {
  color: #444;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 18px;
  margin-top: 5px;
  font-weight: 500;
}

.add-folder-block {
  .my-class-add-group-border {
    margin-left: 60px;
    display: inline-block;
    width: 92%;
    padding: 5px 0 0 0;
    margin-bottom: 10px;
  }
  .add-folder-span{
    float: left;
    width: 45px;
    height: 45px;
    display: block;
    &.span-right{
      float: right;
    }
  }
} 

.create-folder-icon{
  cursor: pointer;
}

.create-folder{
  display: inline-block;
  font-size: 17px;
  letter-spacing: 0;
  margin-bottom: 20px;
  cursor: pointer;
}
.create-folder img{
  width: 26px;
  margin: auto;
  display: inline-block;
  margin-right: 2px;
  margin-bottom: 6px;
}
.create-new-folder-input{
  .textBox-wrapper{
    display: inline-block;
    width: 75%;
  }
  .global-unit-searchbar{
    width: 100%;
    padding-left: 10px;
  }
  .icon-icon-check{
    margin-left: 10px;
    border: 1px solid #9b9b9b;
    background: #3491b7;
    color: #fff;
    height: 32px;
    display: inline-block;
    vertical-align: top;
    padding: 5px;
    cursor: pointer;
    border-radius: 50%;
  }
}

.image-size-in-my-library {
  max-height: 7em;
  box-shadow: 0 0.5px 2px 0 rgba(0,0,0,0.1), 0 2px 6px 0 rgba(0,0,0,0.05);
  border: 1.5px solid #F5F5F5;
  border-radius: 4px;
  overflow: hidden;
}

.unit-name-my-library{
 color: #4A4A4A;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.my-library-header-text {
  color: #4A4A4A;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 15px;
  height:1em;
  text-overflow: ellipsis;
  overflow: hidden;
}

.library-item-sub-text {
  color: #9B9B9B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  line-height: 10px;
  margin-top:5px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 2em;
}

.library-item-author {
  color: #9B9B9B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  line-height: 10px;
  margin-top:5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.unit-sub-text{
  color: #9B9B9B;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  line-height: 10px;
  margin-top:5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.select{
  color: #009BBD;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height:16px;
  text-align: right;
  float: right;
  margin-right: 40px;
}

.each-item{
  padding-left: 15px;
  padding-top: 5px;
  .media-body {
    border-top: 1px solid #CCD2DE;
  }
  .media{
    height: 70px;
  }
}

.folder-details {
  .each-item:nth-child(4){
    clear: left;
  }
}

#item-detail{
  border-top: 1px solid #CCD2DE;
  padding-left: 0;
  padding-right: 0;
}

#supress-link{
  text-decoration: none;
  color:yellow;
}

a:focus, a:hover{
  text-decoration: none !important;
  outline:none !important;
}

.my-library-tabs{
   box-sizing: border-box;
   height: 30px;
   width: 118px;
   border-top: 1px solid  #9B9B9B;
  border-left: 1px solid  #9B9B9B;
  border-bottom: 1px solid  #9B9B9B;
   background-color: #009BBD;
  padding: 8px 28px 8px 28px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  line-height: 14px;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
 }

.my-library-hero{
  height: 124px;
  color: #FFFFFF;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 52px;
  font-weight: 800;
  line-height: 55px;
  padding: 100px 100px 60px 48px;
}

@media only screen and (min-width: 1498px) {
  .select{
    color: #009BBD;
    //font-family: "ProximaNova";
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height:16px;
    text-align: right;
    float: right;
    margin-right: 80px;
  }
}

.for-scroll{
  overflow-y: auto;
  position: absolute;
  top: 60px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.add-resource-image-units
{
  height: 15px;
  width: 15px;
  color: #9B9B9B;
  margin-left: 10px;
}

.can-hover:hover {
  cursor: pointer;
}

.my-image-library-item{
  width:100%;
}

.resource-content-margin{
  overflow: auto;
  margin-bottom: 35px;
  margin-top: 10px;
}

.header-icon-my-library{
    font-size: 30px;
    color: #009BBD;
    margin: -5px 6px 10px 8px;
    cursor: pointer;
}

.assign-button-students {
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #9B9B9B;
  background-color: white;
  border-radius: 5px 0 0 5px;
  border: 1px solid #ccd2de;
  border-right: none;
  padding: 8px 25px 8px 25px;
  line-height: 13px;
  text-align: center;
  width: 116px;
}
.assign-button-groups {
  font-size: 11px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #9B9B9B;
  background-color: white;
  border-radius: 0 5px 5px 0;
  border: 1px solid #9B9B9B;
  padding: 8px 25px 8px 25px;
  line-height: 13px;
  text-align: center;
  width: 116px;
}
.assign-button-students-selected {
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: white;
  background-color: #009BBD;
  border-radius: 5px 0 0 5px;
  border: 1px solid #ccd2de;
  border-right: none;
  padding: 8px 25px 8px 25px;
  line-height: 13px;
  text-align: center;
  width: 116px;
}
.assign-button-groups-selected {
  font-size: 11px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: white;
  background-color: #009BBD;
  border-radius: 0 5px 5px 0;
  border: 1px solid #9B9B9B;
  padding: 8px 25px 8px 25px;
  line-height: 13px;
  text-align: center;
  width: 116px;
}

.folderFilter{
  float:right;
  font-weight:500;
  cursor:pointer;
  &:hover{
    text-decoration:underline;
  }
}

.libraryFilter{
  font-weight:500;
  cursor:pointer;
  &:hover{
    text-decoration:underline;
  }
}

.librarySortFilter{
  text-align:center;
}

.sortLabel{
  float:right;
}
