#elearning-popup {
    .elearning-popup-option {
        cursor: pointer;
        padding-bottom: 10px;
        color: #3498DB;
        //font-family: ProximaNova;
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
        line-height: 19px;
        &.disabled {
            display: none;
        }
    }
}
