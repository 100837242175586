.format-navigate-buttons {
  float: right;
  width: 100%;
  max-width: 200px;
  button {
    width:50%;
    box-sizing: border-box;
    height: 30px;
    border: 1px solid #9B9B9B;
    background-color: #FFFFFF;
    color: #9B9B9B;
    line-height: 15px;
    text-align: center;
    font: {
      size: 12px;
      weight: bold;
      family: "ProximaNova";
    }
  }
  button:first-child {
    float:left;
    width: 25%;
    border: {
      radius: 4px 0 0 4px;
      right: none;
    }
  }
  button:last-child{
    float: right;
    width: 25%;
    border: {
      radius: 0 4px 4px 0;
      left: none;
    }
  }
}

.month-year-demo-icon{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 15px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:10px;
  height:8px;
  color: #009BBD;
  cursor: pointer;
}


.rbc-agenda-content{
  overflow-x:hidden;
}

.format-grid-list{
  color: #9B9B9B;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  text-align: center;
  width: 100%;
  max-width: 300px;
  margin: auto;

  button {
    width: 50%;
  }

  button:active{
    background-color: #009BBD;
    color: white;
  }
}

.format-grid-list :first-child{
  height: 30px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  border: 1px solid #9B9B9B;
  border-radius: 4px 0 0 4px;
  background-color: #FFFFFF;
}

.format-grid-list :last-child{
  height: 30px;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  border: 1px solid #9B9B9B;
  border-radius: 0 4px 4px 0;
  background-color: #FFFFFF;
}

.navigate-demo-icon{
  //font-family: "sw-font";
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 15px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:10px;
  height:8px;
}

.format-month-year{
  height: 26px;
  display: inline;
  color: #4A4A4A;
  //font-family: "ProximaNova";
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  line-height: 26px;

  // Date parts
  span {
    padding-right: .2em;
  }
}

.active-tab {
  background-color: #009BBD  !important;
  color: white;
}
