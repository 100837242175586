.modal-top-wrapper {
  display: flex;
}

.modal-footer-wrapper {
  display: flex;
  background-color: dodgerblue;
  justify-content: space-around;
  align-items: center;

  a {
    color: white;
    text-decoration: underline;
  }
}

.modal-top-right-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 650px;
    margin: 30px auto;
  }
}

.rocket-image-modal {
  max-height: 400px;
}

.modal-content-custom {
  border: solid 5px dodgerblue;
  margin: 10px;
  border-radius: 6px;
}

.modal-main-text {
  text-align: center;
}
